import './ConfirmationDialog.css'
import React from 'react'
import { connect } from 'react-redux'
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'

/*
  dialogProps object should look like this:

{
  visible: false,
  onConfirmHandler: () => confirmHandler(),
  onCancelHandler: () => cancelHandler(),
  isTitleBarHidden: false,
  dialogHeader: 'confirm operation',
  confirmationText: 'do the thing?',
  confirmButtonLabel: 'confirm',
  cancelButtonLabel: 'cancel',
  confirmButtonCustomClasses: 'p-button-danger',
  cancelButtonCustomClasses: 'p-button-warning'
}
*/

const ConfirmationDialog = props => {
  const handleCancel = () => {
    props.dialogProps.onCancelHandler()
  }

  const handleConfirm = () => {
    props.dialogProps.onConfirmHandler()
  }

  const dialogFooter = (
    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
      <div style={{ display: 'inline-block', marginLeft: '.5em' }}>
        <Button
          icon='pi pi-check'
          className={`confirmation-button ${props?.dialogProps?.confirmButtonCustomClasses}`}
          label={props?.dialogProps?.confirmButtonLabel}
          onClick={handleConfirm}
          disabled={props.disabled}
        />
      </div>
      <div style={{ display: 'inline-block', marginLeft: '.5em' }}>
        <Button
          icon='pi pi-times'
          className={`cancel-button ${props?.dialogProps?.cancelButtonCustomClasses}`}
          label={props?.dialogProps?.cancelButtonLabel}
          onClick={handleCancel}
          disabled={props.disabled}
        />
      </div>
    </div>
  )
  return (
    <Dialog
      className={`confirmation-dialog ${
        props?.dialogProps?.isTitleBarHidden
          ? 'confirmation-dialog-no-titlebar'
          : ''
      }`}
      header={props?.dialogProps?.dialogHeader || ''}
      visible={props?.dialogProps?.visible}
      onHide={handleCancel}
      footer={dialogFooter}
    >
      <div>
        <div>
          <span>
            <label className='bold-text'>
              {props?.dialogProps?.confirmationText || ''}
            </label>
          </span>
        </div>
      </div>
    </Dialog>
  )
}

export default connect()(ConfirmationDialog)
