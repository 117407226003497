import { React, useEffect, useState } from 'react'
import { HashRouter as Router, Routes, Route } from 'react-router-dom'

import { useDispatch, useSelector } from 'react-redux'
import AppTitle from 'components/UI/Typography/AppTitle'
import Dashboard from 'components/Admin/Dashboard'
import ProtectedRoute from 'components/ProtectedRoute'

import './App.css'
import { loginActions } from 'actions'
import { ProgressSpinner } from 'primereact/progressspinner'
import LoginForm from 'components/Login'

const isAuthenticatedSelector = state => state.loginUserReducer?.isAuthenticated

const App = () => {
  const isAuthenticated = useSelector(isAuthenticatedSelector)
  const dispatch = useDispatch()
  const [isMessageReceived, setIsMessageReceived] = useState(null)

  useEffect(() => {
    if (process.env.REACT_APP_INCLUDE_NXCERTIFICATES_LOGIN === 'false') {
      const messageTimeoutDuration = 5000
      if (!window.opener) {
        if (!isAuthenticated) {
          window.location.href = process.env.REACT_APP_CORE_URL
        } else { setIsMessageReceived(true) }
      } else {
        const isPageInitiated = window.sessionStorage.getItem('isPageInitiated')
        if (isPageInitiated) { setIsMessageReceived(true) } else {
          window.opener.postMessage('ready', process.env.REACT_APP_CORE_URL)
          const fallbackAction = () => {
            if (isAuthenticated) { setIsMessageReceived(true) } else {
            // redirection to core
              window.location.href = process.env.REACT_APP_CORE_URL
            }
          }
          const messageTimeout = setTimeout(fallbackAction, messageTimeoutDuration)

          const handleMessage = (event) => {
            if (event.origin !== process.env.REACT_APP_CORE_URL) {
            // console.warn('Origin not allowed')
              return
            }
            clearTimeout(messageTimeout)
            try {
              const { token: tokenFromCore } = JSON.parse(event.data)
              if (tokenFromCore) {
                const actionPromise = dispatch(loginActions.setInitialState(tokenFromCore))
                if (actionPromise && typeof actionPromise.then === 'function') {
                  actionPromise.then(() => {
                    setIsMessageReceived(true)
                  }).catch((e) => {
                    console.error('Failed to set initial state:', e)
                    setIsMessageReceived(true)
                  })
                } else {
                  setIsMessageReceived(true)
                }
              }
              window.sessionStorage.setItem('isPageInitiated', 'true')
            } catch (e) {
              console.error('Error parsing message data:', e)
              setIsMessageReceived(true)
            }
          }

          window.addEventListener('message', handleMessage)

          return () => {
            window.removeEventListener('message', handleMessage)
            clearTimeout(messageTimeout)
          }
        }
      }
    }
  }, [])

  if (isMessageReceived === null && process.env.REACT_APP_INCLUDE_NXCERTIFICATES_LOGIN === 'false') {
    return (
      <ProgressSpinner
        strokeWidth='4'
        animationDuration='.5s'
      />
    )
  } else {
    return (
      <div className='app-container'>
        <AppTitle />
        <Router>
          <Routes>
            <Route
              path='/*'
              element={
                <ProtectedRoute
                  isAuthenticated={isAuthenticated}
                  component={Dashboard}
                />
            }
            />
            {process.env.REACT_APP_INCLUDE_NXCERTIFICATES_LOGIN === 'true' && <Route path='/login' element={<LoginForm />} />}
          </Routes>
        </Router>
      </div>
    )
  }
}

export default App
