import React from 'react'
import { Navigate } from 'react-router-dom'
import store from 'stores/store'

const ProtectedRoute = ({
  component: Component,
  isAuthenticated,
  ...props
}) => {
  if (isAuthenticated) {
    return <Component {...props} />
  } else {
    if (process.env.REACT_APP_INCLUDE_NXCERTIFICATES_LOGIN === 'true') {
      return (<Navigate to={{ pathname: '/login', state: { from: props.location } }} />)
    } else {
      // User is not authenticated, redirect to login page
      window.location.href = process.env.REACT_APP_CORE_URL
    }
  }
}

export default ProtectedRoute
