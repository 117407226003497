import { combineReducers } from 'redux'
import certificateReducer from './certificate-reducer'
import loginUserReducer from './login-user-reducer'
import tagDefinitionReducer from './tag-definition-reducer'
import templateReducer from './template-reducer'
import userReducer from './user-reducer'

export default combineReducers({
  certificateReducer,
  loginUserReducer,
  tagDefinitionReducer,
  templateReducer,
  userReducer
})
