import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from 'primereact/button'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { Dialog } from 'primereact/dialog'
import { Toast } from 'primereact/toast'
import ActionButton from 'components/UI/ActionButton'
import CertificatesView from 'components/Admin/CertificatesView'
import UploadCard from 'components/UploadCard'
import assets from 'assets'
import { templateActions } from 'actions'

import './TemplatesView.css'

const userSelector = state => state.loginUserReducer.user
const templateCountSelector = state => state.templateReducer.count
const templateErrorSelector = state => state.templateReducer.error
const templateListSelector = state => state.templateReducer.templates
const templateFetchingSelector = state => state.templateReducer.fetching

const TemplatesView = () => {
  // eslint-disable-next-line no-console
  const dispatch = useDispatch()
  // const navigate = useNavigate()
  const toastRef = useRef(null)
  const [currentPage, setCurrentPage] = useState(0)
  const [expandedRows, setExpandedRows] = useState(null)
  const [filters, setFilters] = useState({})
  const [filterString, setFilterString] = useState('')
  const [firstIndex, setFirstIndex] = useState(0)
  const [
    isDeleteConfirmationDialogVisible,
    setIsDeleteConfirmationDialogVisible
  ] = useState(false)

  const [isUploadCardDialogVisible, setIsUploadCardDialogVisible] =
    useState(false)

  const [selectedTemplate, setSelectedTemplate] = useState()
  const [sortField, setSortField] = useState('')
  const [sortOrder, setSortOrder] = useState('asc')

  const error = useSelector(templateErrorSelector)
  const isDataFetching = useSelector(templateFetchingSelector)
  const templates = useSelector(templateListSelector)
  const templateCount = useSelector(templateCountSelector)
  const user = useSelector(userSelector)
  const userId = user._id
  useEffect(() => {
    if (userId) {
      dispatch(
        templateActions.getOwnedTemplates(
          userId,
          currentPage,
          filterString,
          sortField,
          sortOrder
        )
      )
    }
  }, [dispatch, userId, currentPage, filterString, sortField, sortOrder])

  useEffect(() => {
    setFirstIndex(0)
    setCurrentPage(0)
  }, [filters])

  useEffect(() => {
    if (error) {
      let errorDetail = ''
      if ('response' in error) {
        errorDetail = error.response?.data?.status?.message
      } else if ('code' in error) {
        errorDetail = error.code
      }
      toastRef.current.show({
        severity: 'error',
        summary: assets.SERVER_MESSAGES.ERR,
        detail: assets.SERVER_MESSAGES.ERRORS[errorDetail],
        life: 3000
      })
      if (
        assets.SERVER_MESSAGES.ERRORS[errorDetail] ===
        assets.SERVER_MESSAGES.ERRORS.ERR_EXPIRED_TOKEN
      ) {
        dispatch({ type: 'RESET_USER' })
      } else {
        dispatch({ type: 'RESET_TEMPLATES' })
      }
    }
  }, [error])

  const actionsTemplateBody = template => {
    return (
      <div className='template-actions-wrapper'>
        <ActionButton
          icon='pi-download'
          click={() => {
            handleDownloadTemplateClick(template)
          }}
          textValue={assets.BUTTONS.DOWNLOAD}
        />
        <ActionButton
          icon='pi-times'
          click={() => {
            handleDeleteTemplateClick(template)
          }}
          disabled={
            // false
            typeof template.certificatesCount !== 'undefined' &&
            template.certificatesCount > 0
          }
          textValue={assets.BUTTONS.DELETE}
          toolTipText={
            typeof template.certificatesCount !== 'undefined' &&
            template.certificatesCount > 0 &&
            assets.TOOLTIPS.TEMPLATE_DELETE_DISABLED
          }
        />
      </div>
    )
  }

  const allowExpansion = () => {
    return true
  }

  // const collapseAll = () => {
  //   setExpandedRows(null)
  // }

  // const expandAll = () => {
  //   const _expandedRows = {}

  //   templates.forEach(p => (_expandedRows[`${p.id}`] = true))

  //   setExpandedRows(_expandedRows)
  // }

  const handleConfirmDeletionButtonClick = async () => {
    dispatch(templateActions.deleteTemplate(user._id, selectedTemplate._id))
    onTemplatesDeleteModalHide()
  }

  const handleDeleteTemplateClick = async template => {
    setIsDeleteConfirmationDialogVisible(true)
    setSelectedTemplate(template)
  }

  const handleDownloadTemplateClick = template => {
    const binaryString = window.atob(template.content)

    const len = binaryString.length
    const bytes = new Uint8Array(len)
    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i)
    }

    const blob = new Blob([bytes], { type: 'application/octet-stream' })
    const url = URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', template.name)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  const handlePageChange = evt => {
    const page = evt.page
    setCurrentPage(page)
    setFirstIndex(page * 5)
  }

  const handlePageSort = evt => {
    setSortField(evt.sortField)
    setSortOrder(evt.sortOrder)
  }

  const handleTemplateUpload = templateFile => {
    setIsUploadCardDialogVisible(false)
    dispatch(templateActions.addTemplate(user._id, templateFile))
  }

  const handleUploadTemplateClick = () => {
    setIsUploadCardDialogVisible(true)
  }

  const handleRowExpand = e => {
    setExpandedRows({ [e.data._id]: true })
    setSelectedTemplate(e.data)
  }

  const handleRowCollapse = e => {
    setExpandedRows({})
  }

  const onTemplatesDeleteModalHide = () => {
    setIsDeleteConfirmationDialogVisible(false)
  }

  const renderDeleteFooter = () => {
    return (
      <div className='templates-deletion-dialog-footer'>
        <Button
          label='Close'
          icon='pi pi-times'
          onClick={() => onTemplatesDeleteModalHide()}
          className='p-button-text'
        />
        <Button
          label='Confirm'
          icon='pi pi-check'
          onClick={handleConfirmDeletionButtonClick}
          autoFocus
        />
      </div>
    )
  }

  const renderDeleteHeader = () => {
    return (
      <div>
        <div>{`${assets.HEADERS.DELETE_TEMPLATE} ${selectedTemplate.name} ?`}</div>
        <p className='header-description'>
          {assets.DESCRIPTIONS.DELETE_TEMPLATE}
        </p>
      </div>
    )
  }

  const rowExpansionTemplate = templateData => {
    return (
      <CertificatesView
        generatedCertificates={false}
        handleCertificateAddedToTemplate={templateId => {
          dispatch(templateActions.getOwnedTemplates(user._id))
        }}
        template={templateData}
      />
    )
  }

  const templatesDataTableHeader = () => {
    return (
      <div className='datatable-header-wrapper'>
        {/* <TableHeaderTitle icon='pi-database' title='Templates' /> */}
        <ActionButton
          icon='pi-upload'
          textValue={assets.BUTTONS.UPLOAD_TEMPLATE}
          click={handleUploadTemplateClick}
        />
      </div>
    )
  }

  return (
    <>
      <Toast ref={toastRef} />

      {isDeleteConfirmationDialogVisible && (
        <Dialog
          className='delete-template-dialog'
          footer={renderDeleteFooter()}
          header={renderDeleteHeader()}
          onHide={() => {
            setIsDeleteConfirmationDialogVisible(false)
          }}
          visible={isDeleteConfirmationDialogVisible}
        />
      )}
      {isUploadCardDialogVisible && (
        <Dialog
          className='upload-card-dialog'
          header={assets.HEADERS.UPLOAD_TEMPLATE}
          visible={isUploadCardDialogVisible}
          onHide={() => {
            setIsUploadCardDialogVisible(false)
          }}
        >
          <p className='header-description'>
            {assets.DESCRIPTIONS.UPLOAD_TEMPLATE}
          </p>
          <UploadCard
            fileType='docx'
            handleUploadFile={handleTemplateUpload}
          />
        </Dialog>
      )}
      <DataTable
        className='templates-datatable'
        currentPageReportTemplate='{first} - {last} of {totalRecords} templates'
        dataKey='_id'
        editMode='row'
        expandedRows={expandedRows}
        first={firstIndex}
        header={templatesDataTableHeader}
        lazy
        loading={isDataFetching}
        onPage={handlePageChange}
        onRowCollapse={handleRowCollapse}
        onRowExpand={handleRowExpand}
        onSelectionChange={e => setSelectedTemplate(e.value)}
        onSort={handlePageSort}
        paginator
        paginatorTemplate='PrevPageLink PageLinks NextPageLink CurrentPageReport'
        responsiveLayout='scroll'
        rowExpansionTemplate={rowExpansionTemplate}
        rows={5}
        selection={selectedTemplate}
        sortField={sortField}
        sortOrder={sortOrder}
        stripedRows
        totalRecords={templateCount}
        value={templates}
        // filters={filters}
        // filterDisplay='row'
        // globalFilterFields={['fullName', 'school', 'userId']}
        // onRowEditComplete={handleEdit}
      >
        {/* <Column selectionMode='single' headerStyle={{ width: '3em' }} /> */}
        <Column expander={allowExpansion} style={{ width: '3rem' }} />
        <Column field='name' header='Template Name' sortable />
        <Column body={actionsTemplateBody} />
      </DataTable>
    </>
  )
}

export default TemplatesView
