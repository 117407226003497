const INITIAL_STATE = {
  templates: [],
  count: 0,
  error: null,
  fetching: false,
  fetched: false
}

const templateReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'GET_TEMPLATES_PENDING':
    case 'GET_OWNED_TEMPLATES_PENDING':
    case 'ADD_TEMPLATE_PENDING':
    case 'DELETE_TEMPLATE_PENDING':
    case 'GET_TEMPLATE_JOB_STATUS_PENDING':
      return {
        ...state,
        fetching: true,
        fetched: false,
        error: null
      }
    case 'GET_TEMPLATES_FULFILLED':
    case 'GET_OWNED_TEMPLATES_FULFILLED':
    case 'ADD_TEMPLATE_FULFILLED':
    case 'DELETE_TEMPLATE_FULFILLED':
    case 'GET_TEMPLATE_JOB_STATUS_FULFILLED':
      return {
        ...state,
        templates: action.payload.data.data.templates,
        count: action.payload.data.data.count,
        error: null,
        fetching: false,
        fetched: true
      }
    case 'GET_TEMPLATES_REJECTED':
    case 'GET_OWNED_TEMPLATES_REJECTED':
    case 'ADD_TEMPLATE_REJECTED':
    case 'DELETE_TEMPLATE_REJECTED':
    case 'GET_TEMPLATE_JOB_STATUS_REJECTED':
      return {
        ...state,
        error: action.payload,
        fetching: false,
        fetched: false,
        templates: []
      }

    case 'RESET_TEMPLATES':
      return INITIAL_STATE

    default:
      return state
  }
}

export default templateReducer
