import React from 'react'
import styles from './AppTitle.module.css'

const AppTitle = () => {
  return (
    <div className={`${styles['title-wrapper']}`}>
      <div className={`${styles['image-container']}`}>
        <img
          className={`${styles.logo}`}
          src='/assets/images/updated_logo-slogan.webp'
          alt='logo'
        />
        <div className={`${styles['title-container']}`}>
          <i className='pi pi-file-edit' style={{ fontSize: '2.5rem' }} />
          <h1 className={`${styles['title-heading']}`}>
            Certificate Generator
          </h1>
        </div>
      </div>
    </div>
  )
}

export default AppTitle
