import { React, useCallback, useEffect, useRef, useState, memo } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { Button } from 'primereact/button'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { Dialog } from 'primereact/dialog'
import { Dropdown } from 'primereact/dropdown'
import { Tag } from 'primereact/tag'
import { Toast } from 'primereact/toast'
import ActionButton from 'components/UI/ActionButton'
import CertificateDataInput from '../CertificateDataInput'
import Select from 'react-select'
import TableHeaderTitle from 'components/UI/TableHeaderTitle'
import UploadCard from 'components/UploadCard'
import SimpleReactValidator from 'simple-react-validator'
import { certificateActions, loginActions, tagActions, tagDefinitionActions, userSuggestionsActions } from 'actions'
import { SplitButton } from 'primereact/splitbutton'
import assets from 'assets'

import './CertificatesView.css'
import CertificateShareDialog from 'components/CertificateShareDialog'

const userSelector = state => state.loginUserReducer.user
const certificateErrorSelector = state => state.certificateReducer.error
const certificateFetchingSelector = state => state.certificateReducer.fetching
const certificateListSelector = state => state.certificateReducer.certificates
const certificateCountSelector = state => state.certificateReducer.count
const tagDefinitionListSelector = state => state.tagDefinitionReducer.tagDefinitionList
const usersSuggestionsSelector = state => state.userReducer.userSuggestions
const facetsSelector = state => state.loginUserReducer.user?.facets

const statusIcons = {
  REJECTED: 'pi-times',
  PENDING: 'pi-clock',
  FULFILLED: 'pi-check'
}

const statusTypes = {
  REJECTED: 'REJECTED',
  PENDING: 'PENDING',
  FULFILLED: 'FULFILLED'
}

const CertificatesView = ({ generatedCertificates, handleCertificateAddedToTemplate, template }) => {
  const dispatch = useDispatch()
  // const token = store.getState().loginUserReducer.user.token

  const validator = useRef(
    new SimpleReactValidator({
      element: message => <div style={{ color: 'tomato' }}>{message}</div>,
      messages: {
        required: assets.GENERAL.VALIDATION.FIELD_REQUIRED
      }
    })
  )
  const [, updateState] = useState()
  const forceUpdate = useCallback(() => updateState({}), [])

  const toastRef = useRef(null)

  const [currentPage, setCurrentPage] = useState(0)
  const [filters, setFilters] = useState({
    date: { value: null, matchMode: 'startsWith' },
    name: { value: null, matchMode: 'startsWith' },
    status: { value: null, matchMode: 'startsWith' }
  })
  const [filterString, setFilterString] = useState('')
  const [firstIndex, setFirstIndex] = useState(0)
  const [selectedStatus, setSelectedStatus] = useState(null)
  const [sortField, setSortField] = useState('')
  const [sortOrder, setSortOrder] = useState('asc')
  const [selectedCertificates, setSelectedCertificates] = useState([])
  const [selectedCertificateForPermission, setSelectedCertificateForPermission] = useState(null)

  const [isTagDialogVisible, setIsTagDialogVisible] = useState(false)
  const [tagDefinitonOptions, setTagDefinitionOptions] = useState([])
  const [selectedTagDefinition, setSelectedTagDefinition] = useState(null)

  const [isRemoveCertificatesDialogVisible, setIsRemoveCertificatesDialogVisible] = useState(false)

  const [isRemoveTagDialogVisible, setIsRemoveTagDialogVisible] = useState(false)
  const [tagToDelete, setTagToDelete] = useState(null)

  const [loading, setLoading] = useState(false)
  const [
    isAddNewCertificateDialogVisible,
    setIsAddNewCertificateDialogVisible
  ] = useState(false)
  const [isUploadCardDialogVisible, setIsUploadCardDialogVisible] =
    useState(false)

  const certificates = useSelector(certificateListSelector, shallowEqual)
  const error = useSelector(certificateErrorSelector, shallowEqual)
  const isDataFetching = useSelector(certificateFetchingSelector)
  const certificateCount = useSelector(certificateCountSelector, shallowEqual)
  const tagDefinitionList = useSelector(tagDefinitionListSelector, shallowEqual)
  const usersSuggestions = useSelector(usersSuggestionsSelector, shallowEqual)

  const templateId = template?._id || null
  const user = useSelector(userSelector)
  const userId = user._id
  const facets = useSelector(facetsSelector, shallowEqual)

  // User Suggestions
  const [isActionDialogVisible, setIsActionDialogVisible] = useState(false)
  const [usersSuggestionsOptions, setUsersSuggestionsOptions] = useState([])
  const [selectedUserSuggestion, setSelectedUserSuggestion] = useState(null)
  const [studentFilters, setStudentFilters] = useState({
    username: { value: null, matchMode: 'startsWith' }

  })
  const [studentFilterString, setStudentFilterString] = useState('')

  useEffect(() => {
    if (templateId) {
      dispatch(
        certificateActions.getCertificates(
          templateId,
          currentPage,
          filterString,
          sortField,
          sortOrder
        )
      )
    } else {
      if (facets.indexOf('cert-generator') !== -1 && generatedCertificates) {
        dispatch(
          certificateActions.getGeneratedCertificates(
            userId,
            currentPage,
            filterString,
            sortField,
            sortOrder
          )
        )
      } else {
        dispatch(
          certificateActions.getOwnedCertificates(
            userId,
            currentPage,
            filterString,
            sortField,
            sortOrder
          )
        )
      }
    }
  }, [templateId, dispatch, currentPage, filterString, sortField, sortOrder])

  useEffect(() => {
    if (error) {
      let errorDetail = ''
      if ('response' in error) {
        errorDetail = error.response?.data?.status?.message
      } else if ('code' in error) {
        errorDetail = error.code
      }
      toastRef.current.show({
        severity: 'error',
        summary: assets.SERVER_MESSAGES.ERR,
        detail: assets.SERVER_MESSAGES.ERRORS[errorDetail],
        life: 3000
      })
      dispatch({ type: 'RESET_CERTIFICATES' })
    }
  }, [error])

  useEffect(() => {
    const filterKeys = Object.keys(filters)
    const filterString = filterKeys
      .map(e => {
        return `${e}=${filters[e].value}`
      })
      .join('&')
    setFilterString(filterString)
    setCurrentPage(0)
    setFirstIndex(0)
  }, [filters])

  useEffect(() => {
    const studentFilterKeys = Object.keys(studentFilters)
    const studentFilterString = studentFilterKeys
      .map(e => {
        return `${e}=${studentFilters[e].value}`
      })
      .join('&')
    setStudentFilterString(studentFilterString)
  }, [studentFilters])

  useEffect(() => {
    if (facets.indexOf('cert-generator') !== -1) { dispatch(userSuggestionsActions.getStudentsSuggestions(studentFilterString)) }
  }, [dispatch, studentFilterString])

  useEffect(() => {
    setTagDefinitionOptions(tagDefinitionList.map(e => {
      return {
        label: e.name,
        value: e._id
      }
    }))
  }, [tagDefinitionList])

  useEffect(() => {
    setUsersSuggestionsOptions(usersSuggestions.map(e => {
      return {
        label: e.username,
        value: e._id
      }
    }))
  }, [usersSuggestions])

  const handleShowTagDialog = () => {
    dispatch(tagDefinitionActions.getApplicableTagDefinitions(user))
    setIsTagDialogVisible(true)
  }

  const handleShowRemoveCertificatesDialog = () => {
    setIsRemoveCertificatesDialogVisible(true)
  }

  const handleShowActionDialog = (rowData) => {
    // dispatch(userSuggestionsActions.getStudentsSuggestions())
    setIsActionDialogVisible(true)
    setSelectedCertificateForPermission(rowData.id)
  }

  const handleShowRemoveTagDialog = (tagId) => {
    setIsRemoveTagDialogVisible(true)
    setTagToDelete(tagId)
  }

  const handleLogOut = () => {
    window.sessionStorage.removeItem('isPageInitiated')
    dispatch(loginActions.resetTokenState())
  }
  const handleDownloadExistingArchive = useCallback((archiveJobId) => {
    certificateActions.downloadExistingArchive(archiveJobId)
  }, [])

  const certificatesDataTableHeader = () => {
    const isGenerating = template?.archiveJobStatus === 'PENDING' || loading
    const isArchiveGenerated = template?.archiveJobId !== null && template?.archiveJobStatus === 'FULFILLED'
    console.warn(isGenerating)
    console.warn(isArchiveGenerated)
    const items = [
      // {
      //   label: assets.BUTTONS.DOWNLOAD_EXISTING_ARCHIVE,
      //   icon: 'pi pi-download',
      //   command: () => handleDownloadExistingArchive(template?.archiveJobId),
      //   visible: !isArchiveGenerated
      // },
      {
        label: assets.BUTTONS.GENERATE_NEW_ARCHIVE,
        icon: 'pi pi-refresh',
        command: () => handleDownloadAllClick(template._id),
        disabled: !isArchiveGenerated,
        visible: isArchiveGenerated
      }
    ]

    return (
      <div className='datatable-header-wrapper certificates-header'>
        {template && (
          <TableHeaderTitle
            className='certificates-table-header'
            icon='pi-book'
            title='Certificates'
          />
        )}
        <div className='action-buttons-wrapper'>

          <Button
            className='refresh-certificates-button'
            icon='pi pi-refresh'
            label={assets.BUTTONS.REFRESH}
            onClick={() => {
              if (templateId) {
                dispatch(
                  certificateActions.getCertificates(
                    templateId,
                    currentPage,
                    filterString,
                    sortField,
                    sortOrder
                  )
                )
              } else {
                if (facets.indexOf('cert-generator') !== -1 && generatedCertificates) {
                  dispatch(
                    certificateActions.getGeneratedCertificates(
                      userId,
                      currentPage,
                      filterString,
                      sortField,
                      sortOrder
                    )
                  )
                } else {
                  dispatch(
                    certificateActions.getOwnedCertificates(
                      userId,
                      currentPage,
                      filterString,
                      sortField,
                      sortOrder
                    )
                  )
                }
              }
              setLoading(false)
              dispatch(certificateActions.getTemplateJobStatus(templateId))
            }}
            tooltip={assets.BUTTONS.REFRESH}
            tooltipOptions={{ position: 'bottom' }}
          />

          {!template && process.env.REACT_APP_INCLUDE_NXCERTIFICATES_LOGIN === 'true'
            ? <Button
                className='delete-certificate-button'
                icon='pi pi-sign-out'
                label={assets.BUTTONS.LOGOUT}
                onClick={handleLogOut}
              />
            : ''}
          {template && facets.indexOf('cert-generator') !== -1
            ? <ActionButton
                icon='pi-file-edit'
                textValue={assets.BUTTONS.ADD_NEW_CERTIFICATE}
                click={handleAddNewCertificateClick}
              />
            : ''}
          {template && facets.indexOf('cert-generator') !== -1
            ? <ActionButton
                icon='pi-upload'
                textValue={assets.BUTTONS.GENERATE_CERTIFICATE_LIST}
                click={handleGenerateCertificatesClick}
              />
            : ''}
          {template && facets.indexOf('cert-generator') !== -1
            ? <Button
                className='tag-certificates-button'
                icon='pi pi-tags'
                label={assets.BUTTONS.TAG_CERTIFICATES}
                onClick={handleShowTagDialog}
                disabled={selectedCertificates.length === 0}
              />
            : ''}
          {template && facets.indexOf('cert-generator') !== -1
            ? <SplitButton
                className='archive-button'
                icon='pi pi-download'
                label={isGenerating
                  ? assets.GENERAL.GENERATING
                  : isArchiveGenerated
                    ? assets.BUTTONS.DOWNLOAD
                    : assets.BUTTONS.GENERATE_ARCHIVE}
                onClick={isArchiveGenerated
                  ? () => handleDownloadExistingArchive(template?.archiveJobId)
                  : () => handleDownloadAllClick(template._id)}
                model={items}
              />
            : ''}
          {template && facets.indexOf('cert-generator') !== -1
            ? <Button
                className='delete-certificate-button'
                icon='pi pi-trash'
                label={assets.BUTTONS.DELETE_CERTIFICATE}
                onClick={handleShowRemoveCertificatesDialog}
                disabled={selectedCertificates.length === 0}
              />
            : ''}

        </div>
      </div>
    )
  }
  const fileNameBodyTemplate = certificateData => {
    return certificateData.status === statusTypes.FULFILLED
      ? (
        <NavLink
          to='#'
          className='certificate-download-link'
          onClick={() => {
            handleDownloadCertificate(certificateData)
          }}
        >
          {certificateData.name}
        </NavLink>
        )
      : (
        <span>{certificateData.name}</span>
        )
  }

  const handleAddNewCertificateClick = () => {
    setIsAddNewCertificateDialogVisible(true)
  }

  const handleCSVFileUpload = (csvFile, isAutomatedPermissionsEnabled) => {
    setIsUploadCardDialogVisible(false)
    dispatch(
      certificateActions.generateCertificates(
        null,
        template._id,
        csvFile,
        isAutomatedPermissionsEnabled,
        currentPage,
        filterString,
        sortField,
        sortOrder
      )
    )
  }

  const handleDownloadCertificate = useCallback(async certificateData => {
    const response = await certificateActions.getCertificateAsFile(user,
      certificateData.id
    )

    if ('data' in response) {
      const link = document.createElement('a')
      const mime = response.headers['content-type']
      const encoding = 'base64'
      const uri = 'data:' + mime + ';' + encoding + ',' + response.data
      link.href = uri
      link.download = certificateData.name
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
  }, [])

  const handleDownloadAllClick = async (templateId) => {
    setLoading(true)
    dispatch(certificateActions.generateCertificatesArchiveForTemplate(templateId))
  }

  const handleGenerateCertificatesClick = () => {
    setIsUploadCardDialogVisible(true)
  }

  const handleRegenerateClick = certificateData => {
    dispatch(
      certificateActions.regenerateCertificate(certificateData.id, template._id)
    )
  }

  const handleFilter = evt => {
    setFilters(evt.filters)
  }

  const handlePageChange = evt => {
    const page = evt.page
    setCurrentPage(page)
    setFirstIndex(page * 10)
  }

  const handlePageSort = evt => {
    setSortField(evt.sortField)
    setSortOrder(evt.sortOrder)
  }

  const renderAddCertificateHeader = () => {
    return (
      <div>
        <div>{assets.HEADERS.GENERATE_SINGLE_CERTIFICATE}</div>
        <p className='header-description'>
          {assets.DESCRIPTIONS.ADD_NEW_CERTIFICATE}
        </p>
      </div>
    )
  }
  const resetToFirstPage = () => {
    setCurrentPage(0)
    setFirstIndex(0)
  }

  const statusBodyTemplate = (certificateData, id) => {
    if ('status' in certificateData) {
      return (
        <Tag
          className={`status-tag ${statusTypes[
            certificateData.status
          ].toLowerCase()}`}
          style={{ fontSize: '1rem' }}
        >
          <div className='status-tag-content'>
            <i
              className={`pi ${statusIcons[certificateData.status]}`}
              style={{ fontSize: '1.1rem', fontWeight: 'bold' }}
            />
            <span>
              {assets.CERTIFICATE_STATUS_MESSAGES[certificateData.status]}
            </span>
            {certificateData.status === statusTypes.REJECTED && (
              <Button
                className='retry-button'
                icon='pi pi-refresh'
                onClick={() => {
                  handleRegenerateClick(certificateData)
                }}
                tooltip={`${assets.BUTTONS.RETRY}`}
              />
            )}
          </div>
        </Tag>
      )
    }
  }

  const tagsBodyTemplate = (item) => {
    return (
      <div>
        {(item.tags && Object.keys(item.tags[0]).length !== 0)
          ? (<div className='tags-container'>
            {
            item.tags.map(e => (
              <div className='tag' key={e.id} style={{ position: 'relative', display: 'inline-flex', alignItems: 'center', margin: '5px' }}>
                {e.tagDefinition?.name}
                {template && (<button
                  onClick={() => {
                    handleShowRemoveTagDialog(e.id)
                  }}
                  style={{
                    position: 'absolute',
                    top: '-5px',
                    right: '-5px',
                    width: '20px',
                    height: '20px',
                    border: 'none',
                    background: '#808080',
                    color: '#FFFFFF',
                    borderRadius: '50%',
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontSize: '12px'
                  }}
                              >
                  ×
                              </button>)}
              </div>
            ))
}
             </div>)
          : <div />}
      </div>
    )
  }

  const actionsBodyTemplate = (rowData) => {
    return (
      <Button
        key={rowData.id}
        label={assets.BUTTONS.SHARE}
        onClick={() => handleShowActionDialog(rowData)}
      />
    )
  }

  const statusFilterItemTemplate = status => {
    return (
      <Tag
        className={`status-tag ${statusTypes[status].toLowerCase()}`}
        style={{ fontSize: '1rem' }}
      >
        <div className='status-tag-content'>
          <i
            className={`pi ${statusIcons[status]}`}
            style={{ fontSize: '1.1rem', fontWeight: 'bold' }}
          />
          <span>{assets.CERTIFICATE_STATUS_MESSAGES[status]}</span>
        </div>
      </Tag>
    )
  }

  const selectedStatusTemplate = (status, props) => {
    if (status) {
      return (
        <Tag
          className={`status-tag ${statusTypes[status].toLowerCase()}`}
          style={{ fontSize: '1rem' }}
        >
          <div className='status-tag-content'>
            <i
              className={`pi ${statusIcons[status]}`}
              style={{ fontSize: '1.1rem', fontWeight: 'bold' }}
            />
            <span>{assets.CERTIFICATE_STATUS_MESSAGES[status]}</span>
          </div>
        </Tag>
      )
    }
    return <span>{props.placeholder}</span>
  }

  const statusRowFilterTemplate = options => {
    return (
      <Dropdown
        value={selectedStatus}
        valueTemplate={selectedStatusTemplate}
        options={Object.keys(statusTypes)}
        onChange={e => {
          setSelectedStatus(e.value)
          options.filterApplyCallback(e.value || null)
        }}
        itemTemplate={statusFilterItemTemplate}
        placeholder={assets.CERTIFICATE_FILTER.STATUS_FILTER_PLACEHOLDER}
        className='p-column-filter'
        showClear
      />
    )
  }

  const handleSelectionChange = (evt) => {
    setSelectedCertificates(evt.value)
  }

  const handleTagSave = () => {
    if (validator.current.allValid()) {
      const certificates = selectedCertificates.map(e => {
        return {
          _id: e.id,
          type: 'Certificate'
        }
      })

      dispatch(tagActions.applyTag(user, selectedTagDefinition.value, certificates, templateId, currentPage, filterString, sortField, sortOrder))
      setSelectedCertificates([])
      handleHideTagDialog()
    } else {
      validator.current.showMessages()
      forceUpdate()
    }
  }

  const handleDeleteSelectedCertificates = () => {
    // TO-DO validator

    const certificates = selectedCertificates.map(e => {
      return {
        _id: e.id,
        type: 'Certificate'
      }
    })

    dispatch(certificateActions.deleteCertificates(certificates, templateId, currentPage, filterString, sortField, sortOrder))

    setSelectedCertificates([])
    handleHideRemoveCertificatesDialog()
  }

  // const handleUserSuggestionSave = () => {
  //   dispatch(userSuggestionsActions.addUserPermission(selectedUserSuggestion.value, selectedCertificateForPermission))

  //   setSelectedCertificateForPermission(null)
  //   setSelectedUserSuggestion(null)
  //   setIsActionDialogVisible(false)
  // }

  const tagDialogFooter = (
    <div>
      <Button label={assets.GENERAL.SAVE} icon='pi pi-save' onClick={handleTagSave} />
    </div>
  )

  const handleTagDefinitionSelectionChange = (evt) => {
    setSelectedTagDefinition(evt)
  }

  const handleHideTagDialog = () => {
    setIsTagDialogVisible(false)
    setSelectedTagDefinition(null)
  }

  const handleHideActionDialog = () => {
    setIsActionDialogVisible(false)
    setSelectedUserSuggestion(null)
  }

  const handleHideRemoveCertificatesDialog = () => {
    setIsRemoveCertificatesDialogVisible(false)
  }

  const handleHideRemoveTagDialog = () => {
    setTagToDelete(null)
    setIsRemoveTagDialogVisible(false)
  }

  const handleRemoveTag = () => {
    dispatch(
      tagActions.deleteTag(
        tagToDelete, template._id,
        currentPage,
        filterString,
        sortField,
        sortOrder
      )
    )
    handleHideRemoveTagDialog()
  }

  // const actionsDialogFooter = (
  //   <div>
  //     <Button label={assets.GENERAL.SAVE} icon='pi pi-save' onClick={handleUserSuggestionSave} />
  //   </div>
  // )

  const removeCertificatesDialogFooter = (
    <div>
      <Button
        label={assets.GENERAL.CANCEL}
        icon='pi pi-times'
        onClick={handleHideRemoveCertificatesDialog}
        className='p-button-text'
      />
      <Button
        label={assets.EVENT.CONFIRM_EVENT}
        icon='pi pi-check'
        onClick={handleDeleteSelectedCertificates}
        autoFocus
      />
    </div>
  )

  const removeTagDialogFooter = (
    <div>
      <Button
        label={assets.GENERAL.CANCEL}
        icon='pi pi-times'
        onClick={handleHideRemoveTagDialog}
        className='p-button-text'
      />
      <Button
        label={assets.EVENT.CONFIRM_EVENT}
        icon='pi pi-check'
        onClick={handleRemoveTag}
        autoFocus
      />
    </div>

  )

  return (
    <div className='certificates-view'>

      {/* onChange={handleTagDefinitionSelectionChange}
              onBlur={() => {
                validator.current.showMessageFor('modelValue')
                forceUpdate()
              }} */}

      <div>
        <Toast icon='pi pi-exclamation-triangle' ref={toastRef} />

        <DataTable
          className='certificates-datatable'
          currentPageReportTemplate='{first} - {last} of {totalRecords} certificates'
          filters={filters}
          filterDisplay='row'
          first={firstIndex}
          header={certificatesDataTableHeader}
          lazy
          loading={isDataFetching}
          onFilter={handleFilter}
          onPage={handlePageChange}
          onSort={handlePageSort}
          selectionMode='checkbox'
          selection={selectedCertificates}
          onSelectionChange={handleSelectionChange}
          sortField={sortField}
          sortOrder={sortOrder}
          paginator
          paginatorTemplate='PrevPageLink PageLinks NextPageLink CurrentPageReport'
          responsiveLayout='scroll'
          rows={10}
          totalRecords={certificateCount}
          value={certificates}
        >
          <Column selectionMode={template ? 'multiple' : ''} headerStyle={{ width: '3rem' }} />
          <Column
            body={fileNameBodyTemplate}
            field='name'
            filter
            filterPlaceholder={
              assets.CERTIFICATE_FILTER.FILENAME_FILTER_PLACEHOLDER
            }
            header='File Name'
            sortable
            style={{ width: '40%' }}
          />
          <Column
            dataType='date'
            field='date'
            // filter
            // filterElement={dateFilterTemplate}
            // filterPlaceholder={
            //   assets.CERTIFICATE_FILTER.DATE_FILTER_PLACEHOLDER
            // }
            header='Date'
            sortable
            style={{ width: '35%' }}
          />
          {facets.indexOf('cert-generator') !== -1 && (generatedCertificates || template) && <Column
            body={statusBodyTemplate}
            field='status'
            filter
            filterElement={statusRowFilterTemplate}
            filterPlaceholder={
              assets.CERTIFICATE_FILTER.STATUS_FILTER_PLACEHOLDER
            }
            header='Status'
            showFilterMenu={false}
            style={{ width: '5%' }}
                                                                                             />}
          <Column
            body={tagsBodyTemplate}
            field='tags'
            header='Tags'
            style={{ width: '30%' }}
          />

          {facets.indexOf('cert-generator') !== -1 && (generatedCertificates || template) && <Column
            body={actionsBodyTemplate}
            field='actions'
            header='Actions'
            style={{ width: '30%' }}
                                                                                             />}

        </DataTable>

      </div>
      {isAddNewCertificateDialogVisible && (
        <Dialog
          className='add-certificate-dialog'
          header={renderAddCertificateHeader()}
          onHide={() => {
            setIsAddNewCertificateDialogVisible(false)
          }}
          visible={isAddNewCertificateDialogVisible}
        >
          <CertificateDataInput
            handleCertificateDialogHide={() => {
              setIsAddNewCertificateDialogVisible(false)
            }}
            handleGenerateCertificate={certificateData => {
              setIsAddNewCertificateDialogVisible(false)
              dispatch(
                certificateActions.generateCertificates(
                  certificateData,
                  template._id,
                  null,
                  currentPage,
                  filterString,
                  sortField,
                  sortOrder
                )
              )

              resetToFirstPage()
            }}
            template={template}
          />
        </Dialog>
      )}
      {isUploadCardDialogVisible && (
        <Dialog
          className='upload-card-dialog'
          header={assets.HEADERS.UPLOAD_CSV}
          visible={isUploadCardDialogVisible}
          onHide={() => {
            setIsUploadCardDialogVisible(false)
          }}
        >
          <p className='header-description'>{assets.DESCRIPTIONS.UPLOAD_CSV}</p>

          <UploadCard fileType='csv' handleUploadFile={handleCSVFileUpload} />
        </Dialog>
      )}

      {
        isTagDialogVisible && (
          <Dialog
            header={assets.HEADERS.TAG_CERTIFICATES}
            onHide={handleHideTagDialog}
            visible={isTagDialogVisible}
            style={{ height: '25vw', width: '20vw' }}
            footer={tagDialogFooter}
          >
            <Select
              className='react-select'
              options={tagDefinitonOptions}
              value={selectedTagDefinition}
              onChange={handleTagDefinitionSelectionChange}
            />
            {validator.current.message(
              'tag',
              selectedTagDefinition,
              'required'
            )}
          </Dialog>
        )
      }
      {isActionDialogVisible && (<CertificateShareDialog certificate={selectedCertificateForPermission} visible={isActionDialogVisible} onHide={() => handleHideActionDialog()} />)}

      {
        isRemoveCertificatesDialogVisible && (
          <Dialog
            header={assets.HEADERS.DELETE_CONFIRMATION}
            onHide={handleHideRemoveCertificatesDialog}
            visible={isRemoveCertificatesDialogVisible}
            footer={removeCertificatesDialogFooter}
          />
        )
      }

      {
        isRemoveTagDialogVisible && (
          <Dialog
            header={assets.HEADERS.DELETE_TAG_CONFIRMATION}
            onHide={handleHideRemoveTagDialog}
            visible={isRemoveTagDialogVisible}
            footer={removeTagDialogFooter}
          />
        )
      }
    </div>
  )
}

// const certificatesViewComparison = (prevProps, nextProps) => {
//   return prevProps.template._id === nextProps.template._id
// }

export default memo(CertificatesView)
