import axios from 'axios'

import { SERVER } from 'config/global'
import store from 'stores/store'
import { REMOVE_CERTIFICATE_USER_SHARE_PERMISSION } from './user-suggestions-actions'

const ADD_CERTIFICATE = 'ADD_CERTIFICATE'
const GET_OWNED_CERTIFICATES = 'GET_OWNED_CERTIFICATES'
const GET_GENERATED_CERTIFICATES = 'GET_GENERATED_CERTIFICATES'
const GET_CERTIFICATES = 'GET_CERTIFICATES'
const GET_USERS_SHARED_CERTIFICATE = 'GET_USERS_SHARED_CERTIFICATE'
const SHARE_CERTIFICATE = 'SHARE_CERTIFICATE'
const GENERATE_CERTIFICATES = 'GENERATE_CERTIFICATES'
const DELETE_CERTIFICATES = 'DELETE_CERTIFICATES'
const GENERATE_CERTIFICATES_ARCHIVE_FOR_TEMPLATE = 'GENERATE_CERTIFICATES_ARCHIVE_FOR_TEMPLATE'

export function addCertificate (
  templateId,
  certificate,
  page = '',
  filter = '',
  sortField = '',
  sortOrder = ''
) {
  const token = store.getState().loginUserReducer.user.token
  return {
    type: ADD_CERTIFICATE,
    payload: async () => {
      let response = await axios.post(
        `${SERVER}/cert-generator-api/templates/${templateId}/certificates`,
        certificate,
        { headers: { Authorization: token } }
      )
      response = await axios.get(
        `${SERVER}/cert-generator-api/templates
/${templateId}/certificates?page=${page}${filter ? '&' + filter : ''
        }&sortField=${sortField}&sortOrder=${sortOrder}`,
        { headers: { Authorization: token } }
      )

      return response
    }
  }
}

export function generateCertificates (
  certificateData,
  templateId,
  csvFile,
  isAutomatedPermissionsEnabled,
  page = '',
  filter = '',
  sortField = '',
  sortOrder = ''
) {
  const token = store.getState().loginUserReducer.user.token

  return {
    type: GENERATE_CERTIFICATES,
    payload: async () => {
      let response = await axios.post(
        `${SERVER}/job-api/templates/${templateId}/jobs`,
        { csvFile, isAutomatedPermissionsEnabled, certificateData },
        { headers: { Authorization: token } }
      )
      response = await axios.get(
        `${SERVER}/cert-generator-api/templates/${templateId}/certificates?page=${page}${filter ? '&' + filter : ''
        }&sortField=${sortField}&sortOrder=${sortOrder}`,
        { headers: { Authorization: token } }
      )

      return response
    }
  }
}

export async function getCertificateAsFile (user, certificateId) {
  const token = store.getState().loginUserReducer.user.token
  const facet = user.facets.includes('cert-generator') ? 'cert-generator' : 'student'

  return await axios.get(`${SERVER}/${facet}-api/certificates/${certificateId}`, {
    headers: {
      Authorization: token
    }
  })
}

export function generateCertificatesArchiveForTemplate (templateId) {
  const token = store.getState().loginUserReducer.user.token
  return {
    type: GENERATE_CERTIFICATES_ARCHIVE_FOR_TEMPLATE,
    payload: axios.post(
    `${SERVER}/job-api/templates/${templateId}/archive-for-template`,
    { templateId },
    { headers: { Authorization: token } }
    )
  }
}

export function getCertificates (
  templateId,
  page = '',
  filter = '',
  sortField = '',
  sortOrder = ''
) {
  const token = store.getState().loginUserReducer.user.token

  return {
    type: GET_CERTIFICATES,
    payload: axios.get(
      `${SERVER}/cert-generator-api/templates/${templateId}/certificates?page=${page}${filter ? '&' + filter : ''
      }&sortField=${sortField}&sortOrder=${sortOrder}`,
      { headers: { Authorization: token } }
    )
  }
}

export function getOwnedCertificates (
  userId,
  page = '',
  filter = '',
  sortField = '',
  sortOrder = ''
) {
  const token = store.getState().loginUserReducer.user.token
  return {
    type: GET_OWNED_CERTIFICATES,
    payload: axios.get(
      `${SERVER}/student-api/users/${userId}/certificates?page=${page}${filter ? '&' + filter : ''
      }&sortField=${sortField}&sortOrder=${sortOrder}`,
      { headers: { Authorization: token } }
    )
  }
}

export function getGeneratedCertificates (
  userId,
  page = '',
  filter = '',
  sortField = '',
  sortOrder = ''
) {
  const token = store.getState().loginUserReducer.user.token
  return {
    type: GET_GENERATED_CERTIFICATES,
    payload: axios.get(
      `${SERVER}/cert-generator-api/users/${userId}/certificates?page=${page}${filter ? '&' + filter : ''
      }&sortField=${sortField}&sortOrder=${sortOrder}`,
      { headers: { Authorization: token } }
    )
  }
}

export function regenerateCertificate (certificateId, templateId) {
  const token = store.getState().loginUserReducer.user.token

  return {
    type: 'REGENERATE_CERTIFICATES',
    payload: axios.post(
      `${SERVER}/job-api/templates/${templateId}/jobs/${certificateId}`,
      {},
      {
        headers: {
          Authorization: token
        }
      }
    )
  }
}

export function deleteCertificates (certificateIdList, templateId,
  page = '',
  filter = '',
  sortField = '',
  sortOrder = '') {
  const token = store.getState().loginUserReducer.user.token

  return {
    type: DELETE_CERTIFICATES,
    payload: async () => {
      await axios.delete(`${SERVER}/cert-generator-api/certificates`, {
        headers: { Authorization: token },
        data: { ids: certificateIdList, templateId }
      })

      const response = await axios.get(
        `${SERVER}/cert-generator-api/templates/${templateId}/certificates?page=${page}${filter ? '&' + filter : ''
        }&sortField=${sortField}&sortOrder=${sortOrder}`,
        { headers: { Authorization: token } }
      )

      return response
    }
  }
}

export function getUsersSharedCertificate (certificateId) {
  const token = store.getState().loginUserReducer.user.token
  return {
    type: GET_USERS_SHARED_CERTIFICATE,
    payload: async () => {
      const response = await axios.get(`${SERVER}/cert-generator-api/certificates/${certificateId}/permissions`, {
        headers: {
          Authorization: token
        }
      })
      return response
    }
  }
}

export function shareCertificate (userId, certificateId) {
  const token = store.getState().loginUserReducer.user.token

  return {
    type: SHARE_CERTIFICATE,
    payload: async () => {
      await axios.post(
            `${SERVER}/cert-generator-api/users/${userId}/permissions`,
            { certificateId },
            { headers: { Authorization: token } }
      )

      const response = await axios.get(`${SERVER}/cert-generator-api/certificates/${certificateId}/permissions`, {
        headers: {
          Authorization: token
        }
      })
      return response
    }
  }
}

export function removeCertificateSharePermission (certificateId, certificatePermissionId, userId, roleId) {
  const token = store.getState().loginUserReducer.user.token
  return {
    type: REMOVE_CERTIFICATE_USER_SHARE_PERMISSION,
    payload: async () => {
      await axios.delete(`${SERVER}/cert-generator-api/users/${userId}/roles/${roleId}/permissions/${certificatePermissionId}`, {
        headers: {
          Authorization: token
        }
      })
      const response = await axios.get(`${SERVER}/cert-generator-api/certificates/${certificateId}/permissions`, {
        headers: {
          Authorization: token
        }
      })
      return response
    }
  }
}

export function getTemplateJobStatus (templateId) {
  const token = store.getState().loginUserReducer.user.token
  return {
    type: 'GET_TEMPLATE_JOB_STATUS',
    payload: async () => {
      const response = await axios.get(
            `${SERVER}/job-api/certificates/status/template/${templateId}`,
            { headers: { Authorization: token } }
      )

      return response
    }
  }
}

export async function downloadExistingArchive (archiveJobId) {
  const token = store.getState().loginUserReducer.user.token

  const response = await axios.get(
    `${SERVER}/download/${archiveJobId}/${archiveJobId}.zip`,
    {
      headers: { Authorization: token },
      responseType: 'blob' // Ensure we handle the response as a file
    }
  )

  // Create a URL for the blob and trigger a download
  const url = window.URL.createObjectURL(new Blob([response.data]))
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', 'certificates.zip')
  document.body.appendChild(link)
  link.click()
  link.remove()
}
