import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import { templateActions } from 'actions'

import './CertificateDataInput.css'
import { Dropdown } from 'primereact/dropdown'
import assets from 'assets'

const userSelector = state => state.loginUserReducer.user
const templateErrorSelector = state => state.templateReducer.error
const templateFetchingSelector = state => state.templateReducer.fetching
const templateListSelector = state => state.templateReducer.templates

const CertificateDataInput = ({
  handleCertificateDialogHide,
  handleGenerateCertificate,
  template
}) => {
  const [certificateState, setCertificateState] = useState({})
  const [isFormValid, setIsFormValid] = useState(false)
  const [selectedTemplate, setSelectedTemplate] = useState(template)
  const dispatch = useDispatch()
  const handleConfirmAddCertificate = async () => {
    handleGenerateCertificate(certificateState)
  }
  const error = useSelector(templateErrorSelector)
  const isDataFetching = useSelector(templateFetchingSelector)
  const templates = useSelector(templateListSelector)
  const user = useSelector(userSelector)
  const userId = user._id

  useEffect(() => {
    if (!template && userId) {
      dispatch(templateActions.getOwnedTemplates(userId))
    }
  }, [template, dispatch, userId])

  useEffect(() => {
    const allFieldsFilled = selectedTemplate && selectedTemplate.templateKeys && selectedTemplate.templateKeys.every(key =>
      certificateState[key] && certificateState[key].trim() !== ''
    )
    setIsFormValid(allFieldsFilled)
  }, [certificateState, selectedTemplate])

  return (
    <div className='certificate-dialog-content'>
      <div className='certificate-data-input-wrapper'>
        {selectedTemplate && Array.isArray(selectedTemplate.templateKeys)
          ? (
              selectedTemplate.templateKeys.map((templateValue, index) => {
                return (
                  <span
                    key={index}
                    className='p-float-label certificate-dialog-input'
                  >
                    <InputText
                      id={templateValue}
                      name={templateValue}
                      value={certificateState.templateValue}
                      onChange={e => {
                        setCertificateState({
                          ...certificateState,
                          [e.target.name]: e.target.value
                        })
                      }}
                    />
                    <label htmlFor={templateValue}>{templateValue}</label>
                  </span>
                )
              })
            )
          : (
            <Dropdown
              className='template-select-dropdown'
              onChange={e => setSelectedTemplate(e.value)}
              options={templates.map(template => template.name)}
              placeholder={assets.DESCRIPTIONS.SELECT_TEMPLATE}
              value={selectedTemplate}
            />
            )}
      </div>
      <div className='certificate-dialog-actions'>
        <Button
          label='Cancel'
          icon='pi pi-times'
          onClick={handleCertificateDialogHide}
          className='p-button-text'
        />
        <Button
          label='Confirm'
          icon='pi pi-check'
          onClick={handleConfirmAddCertificate}
          autoFocus
          disabled={!isFormValid}
        />
      </div>
    </div>
  )
}

export default CertificateDataInput
