import React from 'react'
import { Button } from 'primereact/button'
import { Card } from 'primereact/card'

import styles from './ActionButton.module.css'

function ActionButton (props) {
  return (
    <Card className={`${styles['button-card']}`}>
      <Button
        disabled={props.disabled}
        icon={`pi ${props.icon}`}
        label={props.textValue ? ' ' + props.textValue : ''}
        onClick={props.click}
        severity={props.severity}
        tooltip={props.toolTipText}
        tooltipOptions={{ position: 'bottom', showOnDisabled: true }}
      />
    </Card>
  )
}

export default ActionButton
