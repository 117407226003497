const INITIAL_STATE = {
  tagDefinitionList: [],
  tagDefinitionSuggestions: [],
  error: null,
  fetching: false,
  fetched: false
}

export default function reducer (state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'GET_APPLICABLE_TAG_DEFINITIONS_PENDING':
    case 'GET_TAG_DEFINITIONS_PENDING':
    case 'GET_TAG_DEFINITIONS_SUGGESTIONS_PENDING':
    case 'ADD_TAG_DEFINITION_PENDING':
    case 'UPDATE_TAG_DEFINITION_PENDING':
    case 'DELETE_TAG_DEFINITION_PENDING':
      return { ...state, error: null, fetching: true, fetched: false }
    case 'GET_APPLICABLE_TAG_DEFINITIONS_FULFILLED':
    case 'GET_TAG_DEFINITIONS_FULFILLED':
    case 'ADD_TAG_DEFINITION_FULFILLED':
    case 'UPDATE_TAG_DEFINITION_FULFILLED':
    case 'DELETE_TAG_DEFINITION_FULFILLED':
      return {
        ...state,
        tagDefinitionList: action.payload.data.tagDefinitions,
        count: action.payload.data.count,
        error: null,
        fetched: true,
        fetching: false
      }
    case 'GET_TAG_DEFINITIONS_SUGGESTIONS_FULFILLED':
      return {
        ...state,
        tagDefinitionSuggestions: action.payload.data,
        count: action.payload.data.count,
        error: null,
        fetched: true,
        fetching: false
      }
    case 'GET_APPLICABLE_TAG_DEFINITIONS_REJECTED':
    case 'GET_TAG_DEFINITIONS_REJECTED':
    case 'GET_TAG_DEFINITIONS_SUGGESTIONS_REJECTED':
    case 'ADD_TAG_DEFINITION_REJECTED':
    case 'UPDATE_TAG_DEFINITION_REJECTED':
    case 'DELETE_TAG_DEFINITION_REJECTED':
      return {
        ...state,
        tagDefinitionList: [],
        tagDefinitionSuggestions: [],
        count: 0,
        error: action.payload.response.data,
        fetching: false,
        fetched: false
      }
    case 'RESET':
      return INITIAL_STATE
    default:
      break
  }

  return state
}
