import React from 'react'
import { useSelector } from 'react-redux'
import { Card } from 'primereact/card'
import { TabView, TabPanel } from 'primereact/tabview'
// import LoadingSpinner from 'components/UI/LoadingSpinner'
// import UploadCard from 'components/UploadCard'
import CertificatesPage from 'components/Pages/CertificatesPage'
import TagDefinitionEditor from 'components/TagDefinitionEditor'
import TemplatesView from 'components/Admin/TemplatesView'
import PermissionsCenterPage from 'components/Pages/PermissionsCenterPage'
import './Dashboard.css'
import assets from 'assets'

const facetSelector = state => state.loginUserReducer.user?.facets

const Dashboard = () => {
  const facets = useSelector(facetSelector)
  return (
    <Card className='admin-view-wrapper'>

      {facets.indexOf('admin') !== -1
        ? (
          <TabView>
            <TabPanel header={assets.HEADERS.GIVE_PERMISSION} leftIcon='pi pi-unlock mr-2'>
              <PermissionsCenterPage />
            </TabPanel>
          </TabView>
          )
        : (<TabView>
          <TabPanel
            header={assets.HEADERS.OWNED_CERTIFICATES}
            leftIcon='pi pi-book mr-2'
          >
            <CertificatesPage generatedCertificates={false} />
          </TabPanel>
          {facets.indexOf('cert-generator') !== -1 && (<TabPanel
            header={assets.HEADERS.GENERATED_CERTIFICATES}
            leftIcon='pi pi-file-edit mr-2'
                                                       >
            <CertificatesPage generatedCertificates />
          </TabPanel>)}
          {
          facets.indexOf('cert-generator') !== -1 && (
            <TabPanel
              header={assets.HEADERS.TEMPLATES}
              leftIcon='pi pi-database mr-2'
            >
              <TemplatesView />
            </TabPanel>)
        }
          {
           facets.indexOf('cert-generator') !== -1 && (
             <TabPanel header={assets.HEADERS.TAGS} leftIcon='pi pi-tags mr-2'>
               <TagDefinitionEditor />
             </TabPanel>)
        }
        </TabView>
          )}

    </Card>
  )
}

export default Dashboard

// <TabView className='tabview-wrapper'>
//   <TabPanel header='Upload template' leftIcon='pi pi-upload mr-2'>
//     <UploadCard />
//   </TabPanel>

//   <TabPanel header='Templates' leftIcon='pi pi-database mr-2'>
//     {/* <Suspense fallback={<LoadingSpinner />}> */}
//     <TemplatesView />
//     {/* </Suspense> */}
//   </TabPanel>
//   <TabPanel
//     header='Generate certificates'
//     leftIcon='pi pi-book mr-2'
//   ></TabPanel>
// </TabView>
