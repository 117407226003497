const INITIAL_STATE = {
  userSuggestions: [],
  count: 0,
  certGenerators: [],
  certGeneratorsCount: 0,
  error: null,
  fetching: false,
  fetched: false
}

const userReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'GET_STUDENTS_SUGGESTIONS_PENDING':
    case 'GET_TEACHERS_SUGGESTIONS_PENDING':
    case 'GET_CERT_GENERATORS_PENDING':
    case 'ADD_USER_PERMISSION_PENDING':
    case 'ADD_CERT_GENERATOR_FACET_PENDING':
      return {
        ...state,
        fetching: true,
        fetched: false,
        error: null
      }
    case 'GET_CERT_GENERATORS_FULFILLED':
    case 'ADD_CERT_GENERATOR_FACET_FULFILLED':
      return {
        ...state,
        certGenerators: action.payload.data?.certGenerators,
        certGeneratorsCount: action.payload.data?.certGeneratorsCount,
        error: null,
        fetching: false,
        fetched: true
      }
    case 'GET_CERT_GENERATORS_REJECTED':
    case 'ADD_CERT_GENERATOR_FACET_REJECTED':
      return {
        ...state,
        error: action.payload,
        fetching: false,
        fetched: false,
        certGenerators: []
      }
    case 'GET_STUDENTS_SUGGESTIONS_FULFILLED':
    case 'GET_TEACHERS_SUGGESTIONS_FULFILLED':
    case 'ADD_USER_PERMISSION_FULFILLED':
      return {
        ...state,
        userSuggestions: action.payload.data?.usersSuggestions,
        count: action.payload.data?.count,
        error: null,
        fetching: false,
        fetched: true
      }
    case 'GET_STUDENTS_SUGGESTIONS_REJECTED':
    case 'GET_TEACHERS_SUGGESTIONS_REJECTED':
    case 'ADD_USER_PERMISSION_REJECTED':
      return {
        ...state,
        error: action.payload,
        fetching: false,
        fetched: false,
        userSuggestions: []
      }
    case 'RESET_USERS':
      return INITIAL_STATE

    default:
      return state
  }
}

export default userReducer
