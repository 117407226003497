import React from 'react'
import './TableHeaderTitle.css'

const TableHeaderTitle = ({ className, icon, title }) => {
  return (
    <div className={`${className} datatable-header-title`}>
      <i className={`pi ${icon} mr-2`} />
      <span>{title}</span>
    </div>
  )
}

export default TableHeaderTitle
