const assets = {
  BUTTONS: {
    ADD_NEW_CERTIFICATE: 'Adaugă certificat',
    ADD_NEW_PERMISSIONS: 'Adaugă permisiune',
    ADD_NEW_TAG: 'Adaugă etichetă',
    BACK: 'Înapoi',
    CHOOSE: 'Alege document',
    DELETE: 'Șterge',
    DELETE_CERTIFICATE: 'Șterge certificate',
    DOWNLOAD: 'Descarcă',
    DOWNLOAD_EXISTING_ARCHIVE: 'Descarcă arhivă existentă',
    EDIT: 'Edit selection',
    GENERATE_CERTIFICATE_LIST: 'Generează listă de certificate',
    GENERATE_ARCHIVE: 'Generează arhivă',
    GENERATE_NEW_ARCHIVE: 'Generează arhivă nouă',
    LOGOUT: 'Logout',
    NEXT: 'Următorul',
    SAVE: 'Save steps',
    SHARE: 'Partajează',
    REFRESH: 'Refresh',
    RETRY: 'Regenerează',
    UPLOAD: 'Upload',
    UPLOAD_TEMPLATE: 'Încarcă un template nou',
    VIEW: 'View saved steps',
    TAG_CERTIFICATES: 'Etichetează'
  },

  CERTIFICATE_FILTER: {
    DATE_FILTER_PLACEHOLDER: 'Data generării',
    FILENAME_FILTER_PLACEHOLDER: 'Numele fișierului',
    STATUS_FILTER_PLACEHOLDER: 'Statusul de generare al diplomei'
  },
  CERTIFICATE_STATUS_MESSAGES: {
    FULFILLED: 'Generat',
    PENDING: 'În așteptare',
    REJECTED: 'Eroare la generare'
  },

  DESCRIPTIONS: {
    ADD_NEW_CERTIFICATE:
      'Generează un certificat pe baza template-ului selectat',
    AUTOMATED_PERMISSIONS: '* Pentru a putea oferi permisiuni automat, fișierul .csv trebuie sa conțină câmpul \'username\'',
    DELETE_TEMPLATE: 'Ești sigur că dorești ștergerea template-ului ?',
    GENERATE_SINGLE_CERTIFICATE:
      'Generează un singur certificat pe baza template-ului',
    NO_SHARED_USERS: 'Fluxul nu a fost partajat cu niciun utilizator',
    SELECT_TEMPLATE: 'Selectează un template',
    SELECT_USER: 'Selecteaza un utilizator',
    UPLOAD_CSV: 'Selectează un fișier .csv local sau prin drag and drop',
    UPLOAD_TEMPLATE: 'Selectează un fișier .docx local sau prin drag and drop'
  },
  EVENT: {
    CONFIRM_EVENT: 'Confirmă'
  },
  GENERAL: {
    ADD: 'Adaugă',
    CANCEL: 'Anulează',
    GENERATING: 'Se generează ...',
    SAVE: 'Salvează',
    TOTAL: 'Total',
    VALIDATION: {
      ALPHANUMERIC_CODE: 'Codul poate fi alcătuit doar din litere și cifre',
      FIELD_AGE_INVALID_RANGE: 'Intervalul de varsta este incorect',
      FIELD_MAXIMUM_CHARACTERS: 'Acest câmp nu poate depăși 30 caractere.',
      FIELD_MAXIMUM_INPUT_CHARACTERS:
        'Pentru acest câmp se recomandă a nu se depăși 300 de caractere.',
      MINIMUM_ONE_ANSWER:
        'Întrebarea trebuie să conțină cel putin o variantă de răspuns',
      MAXIMUM_ONE_CORRECT_ANSWER:
        'Întrebarea nu poate conține mai mult de un răspuns corect',
      DATE: 'Câmpul trebuie să conțină o dată validă',
      FIELD_REQUIRED: 'Acest câmp este obligatoriu!',
      BOTH_FIELDS_REQUIRED: 'Ambele câmpuri sunt obligatorii!',
      FIELD_TIME_DIFFERENCE:
        'Timpul MAXIM trebuie să fie mai mare ca timpul MINIM',
      NO_NULL_EXAMPLES: 'Nu se pot adăuga exemple goale',
      VALIDATION_ERROR: 'Eroare de validare'
    }
  },
  HEADERS: {
    DELETE_TAG: 'Ștergere etichetă',
    DELETE_TEMPLATE: 'Ștergere template',
    DELETE_CONFIRMATION: 'Confirmați ștergerea certificatelor?',
    DELETE_FACET_CONFIRMATION: 'Confimați eliminarea permisiunii pentru generare de certificate? ',
    DELETE_TAG_CONFIRMATION: 'Confirmați ștergerea etichetei?',
    GENERATE_SINGLE_CERTIFICATE: 'Generează un singur certificat',
    GENERATED_CERTIFICATES: 'Diplome generate',
    GIVE_AUTOMATED_PERMISSIONS: ' Oferă permisiuni automat',
    GIVE_PERMISSION: 'Oferă permisiuni pentru generare de certificate',
    OWNED_CERTIFICATES: 'Diplomele mele',
    SELECT_TEACHER: 'Selectează profesorii',
    SHARE_CERTIFICATE: 'Partajează certificatul selectat',
    SHARED_USERS: 'Utilizatori partajați',
    STUDENT_PERMISSION: 'Selectează studentul:',
    TAG_CERTIFICATES: 'Etichetează certificatele selectate',
    TAGS: 'Etichete',
    TEMPLATES: 'Templates',
    UPLOAD_CSV: 'Încarcă un fișier CSV',
    UPLOAD_TEMPLATE: 'Încarcă un template nou'
  },
  RESPONSE_STATUS_TYPES: { SUCCESS: 'success', ERROR: 'error' },
  SERVER_MESSAGES: {
    ERRORS: {
      ERR_CERTIFICATE_DATA:
        'Datele pentru generarea certificatului nu au putut fi procesate',
      ERR_CSV_HEADERS:
        'Coloanele din fișierul .csv nu corespund cu template-ul',
      ERR_CERTIFICATE_GENERATE: 'Eroare la generarea certificatului',
      ERR_DOCUMENT_UPLOAD: 'Documentul nu a putut fi încărcat',
      ERR_EXPIRED_TOKEN: 'Token-ul utilizatorului a expirat',
      ERR_INCORRECT_TOKEN: 'Încearcă să te reconectezi la aplicație',
      ERR_LOGIN_NOT_OK: 'Numele utilizatorului sau parolă incorectă',
      ERR_NETWORK: 'Server inaccesibil',
      ERR_NO_CREDENTIALS: 'Credențialele sunt invalide',
      ERR_NO_EXISTING_PATH: 'Nu puteți adăuga un path deja existent',
      ERR_NOT_AUTHORIZED: 'Nu ești autorizat să faci acestă operație',
      ERR_NOT_FOUND: 'Nu am găsit',
      ERR_NOT_IMPLEMENTED: 'Operația nu este încă implementată',
      ERR_SERVER_ERROR: 'S-a întâmplat o eroare pe server',
      ERR_TEMPLATE_NOT_FOUND: 'Template-ul nu a putut fi obținut',
      ERR_TIME_EXPIRED: 'Timpul permis pentru această operație a expirat',
      ERR_USER_NOT_FOUND: 'Numele utilizatorului sau parolă incorectă',
      FILE_UPLOAD_LIMIT_EXCEEDED: 'Limita fișierului este de 100MB'
    },
    SUCCESS: {
      JOB_ENQUEUED: 'Certificate în curs de generare',
      TEMPLATE_CREATED: 'Template salvat cu succes',
      TEMPLATE_DELETED: 'Template șters',
      TEMPLATE_UPDATED: 'Template modificat cu succes'
    },
    ACCEPTED: 'Operația a fost realizată',
    CREATED: 'Entitatea a fost creată',
    ERR: 'Eroare'
  },
  TAG_DEFINITION_LIST: {
    DETAILS_TITLE: 'Detalii etichetă',
    TAG_CONFIRM_DELETE: 'Confirmă ștergerea etichetei',
    TAG_DESCRIPTION: 'Descriere',
    TAG_FACETS: 'Aplicabil fațetelor',
    TAG_MODELS: 'Definiție',
    TAG_NAME: 'Numele etichetei',
    TAG_TYPE: 'Tip'
  },
  PERMISSIONS_CENTER: {
    GENERATOR_NAME: 'Nume profesor',
    ELIMINATE_ACCESS: 'Elimină permisiunea'
  },
  STUDENTS_FILTER: {
    USERNAME_FILTER_PLACEHOLDER: 'Numele de utilizator al elevului'
  },
  TEACHERS_FILTER: {
    USERNAME_FILTER_PLACEHOLDER: 'Numele de utilizator al profesorului'
  },
  TOOLTIPS: {
    TEMPLATE_DELETE_DISABLED:
      'Nu se poate șterge. Există certificate generate pe baza template-ului.'
  }
}
export default assets
