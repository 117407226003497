import React, { useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
// import { Dialog } from 'primereact/dialog'
// import { Divider } from 'primereact/divider'
import { FileUpload } from 'primereact/fileupload'
import { ProgressBar } from 'primereact/progressbar'
import { Toast } from 'primereact/toast'
import { Tooltip } from 'primereact/tooltip'
// import { Tree } from 'primereact/tree'
// import { Skeleton } from 'primereact/skeleton'
import ActionButton from 'components/UI/ActionButton'
import LoadingSpinner from 'components/UI/LoadingSpinner'
import WarningDialog from 'components/UI/WarningDialog'
import assets from 'assets'
import styles from './UploadCard.module.css'
import { Checkbox } from 'primereact/checkbox'

const MAX_PAGE_NUMBER = 1
const acceptedTypes = {
  csv: ['csv'],
  docx: [
    'doc',
    'docx',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
  ]
}

const apiURL =
  process.env.REACT_APP_NODE_ENV === 'production'
    ? process.env.REACT_APP_API_URL_PROD
    : process.env.REACT_APP_API_URL_DEV

const userSelector = state => state.loginUserReducer.user

export default function UploadCard ({ fileType, handleUploadFile }) {
  const [isAutomatedPermissionsCheckBoxVisible, setIsAutomatedPermissionsCheckBoxVisible] = useState(false)
  const [isAutomatedPermissionsChecked, setIsAutomatedPermissionsChecked] = useState(false)
  const [enableAutomatedPermissions, setEnableAutomatedPermissions] = useState(false)
  const dispatch = useDispatch()

  const userToken = useSelector(state => {
    return state.loginUserReducer.userToken
  })

  const userProfileId = useSelector(state => {
    return state.loginUserReducer.userProfileId
  })

  const toastRef = useRef(null)

  const [totalSize, setTotalSize] = useState(0)
  const [isMaxPageNumberModalVisible, setIsMaxPageNumberModalVisible] =
    useState(false)
  const fileUploadRef = useRef(null)

  const beforeDropHandler = e => {
    e.preventDefault()

    if (typeof e.dataTransfer.files !== 'undefined') {
      if (
        e.dataTransfer.files.length > 0 &&
        acceptedTypes[fileType].indexOf(e.dataTransfer.files[0].type) !== -1
      ) {
        return true
      }
    }
    return false
  }

  const beforeSelectHandler = e => {
    const event = e.originalEvent
    event.preventDefault()
    if (event.target && 'value' in event.target) {
      return readURL(event.target.value)
    }
    return false
  }

  const emptyTemplate = () => {
    return (
      <div>
        <div className={styles['empty-template-container']}>
          <i
            className={`pi ${
              fileType === 'docx' ? 'pi-file-word' : 'pi-file-excel'
            } mt-3 p-4`}
            style={{
              fontSize: '3em',
              borderRadius: '50%',
              backgroundColor: 'var(--surface-b)',
              color: 'var(--surface-d)'
            }}
          />
          <span
            style={{
              fontSize: '1.2em',
              color: 'var(--text-color-secondary)',
              textAlign: 'center',
              lineHeight: '1.5em'
            }}
            className='my-3'
          >
            Drag and Drop a{' '}
            <span
              style={{
                fontSize: '1.2rem',
                fontWeight: 'bold'
              }}
            >
              .{fileType}
            </span>{' '}
            file here
          </span>
        </div>
      </div>
    )
  }

  const handleManualUpload = () => {
    if (fileUploadRef) fileUploadRef.current.upload()
  }

  const handleDocumentUpload = async ({ files }) => {
    const [file] = files
    if (file.size / 1024 / 1024 <= 10) {
      // 100MB LIMIT

      const dataURL = await readFileAsURL(file)
      const dataSourceFile = {
        fileName: file.name || Date.now().toLocaleDateString(),
        fileContent: sanitizeURL(dataURL) // file as dataURL
        // fileContent: dataBuffer.toString() //file as buffer
      }
      if (fileUploadRef) fileUploadRef.current.clear()
      setEnableAutomatedPermissions(false)
      handleUploadFile(dataSourceFile, isAutomatedPermissionsChecked)
    } else {
      toastRef.current.show({
        severity: 'error',
        summary: assets.SERVER_MESSAGES.ERRORS.FILE_UPLOAD_LIMIT_EXCEEDED || ''
      })
    }
  }

  const headerTemplate = options => {
    const { className, chooseButton, uploadButton, cancelButton } = options
    const value = totalSize / 1000000
    const formatedValue =
      fileUploadRef && fileUploadRef.current
        ? fileUploadRef.current.formatSize(totalSize)
        : '0 MB'

    return (
      <div
        className={className}
        style={{
          backgroundColor: 'transparent',
          display: 'flex',
          alignItems: 'center'
        }}
      >
        {chooseButton}
        {uploadButton}
        {cancelButton}
        <div className='flex align-items-center gap-3 ml-auto'>
          <span>{formatedValue} / 100 MB</span>
          <ProgressBar
            value={value}
            showValue={false}
            style={{ width: '10rem', height: '12px' }}
          />
        </div>
      </div>
    )
  }

  const itemTemplate = file => {
    return (
      <div className={`${styles['item-template']}`}>
        <div
          className='flex flex-column text-left gap-2 w-full'
          style={{ wordWrap: 'break-word' }}
        >
          {file.name}
          <small>{new Date().toLocaleDateString()}</small>
        </div>
        <ActionButton
          click={handleManualUpload}
          icon='pi-arrow-right'
          textValue={`${assets.BUTTONS.UPLOAD}`}
        />
      </div>
    )
  }

  const onTemplateSelect = async e => {
    setIsAutomatedPermissionsCheckBoxVisible(true)
    const [file] = e.files

    if (
      !('type' in file) ||
      acceptedTypes[fileType].indexOf(file.type) === -1
    ) {
      console.log(file)
    }

    const reader = new FileReader()
    reader.onload = (event) => {
      const content = event.target.result
      const firstLine = content.split('\n')[0].trim()
      const headers = firstLine.split(',').map(header => header.trim())
      if (headers.indexOf('username') !== -1) { setEnableAutomatedPermissions(true) } else { setEnableAutomatedPermissions(false) }
    }

    reader.onerror = () => {
      toastRef.current.show({
        severity: 'error',
        summary: 'Reading Error',
        detail: 'Error occurred while reading the file.'
      })
    }

    reader.readAsText(file)
  }

  const onTemplateUpload = e => {
    let _totalSize = 0

    e.files.forEach(file => {
      _totalSize += file.size || 0
    })

    setTotalSize(_totalSize)
    toastRef.current.show({
      severity: 'info',
      summary: 'Success',
      detail: 'File Uploaded',
      life: 5000
    })
  }

  const onTemplateClear = () => {
    // dispatch({
    //   type: 'FILE_SELECTION_STATUS_CHANGED',
    //   payload: { isFileSelected: false },
    // });
    // dispatch({
    //   type: 'REGISTER_EXTRACTED_DATA',
    //   payload: { extractedData: {} }
    // })
    // dispatch({
    //   type: 'CLEAR_FLOW'
    // })
    // dispatch({
    //   type: 'DESELECT_ALL'
    // })

    // dispatch({
    //   type: 'REMOVE_FLOW'
    // })
    setIsAutomatedPermissionsCheckBoxVisible(false)
    setIsAutomatedPermissionsChecked(false)
    setEnableAutomatedPermissions(false)
    setTotalSize(0)
    // emptyTemplate();
  }
  // const readFileAsBuffer = file => {
  //   return new Promise((resolve, reject) => {
  //     const reader = new window.FileReader()
  //     reader.readAsArrayBuffer(file)
  //     reader.onload = evt => {
  //       resolve(evt.target.result)
  //     }
  //     reader.onerror = err => {
  //       reject(err)
  //     }
  //   })
  // }

  const readFileAsURL = file => {
    return new Promise((resolve, reject) => {
      const reader = new window.FileReader()
      reader.readAsDataURL(file)
      reader.onload = evt => {
        resolve(evt.target.result)
      }
      reader.onerror = err => {
        reject(err)
      }
    })
  }

  const readURL = input => {
    const url = input
    const extension = url.substring(url.lastIndexOf('.') + 1).toLowerCase()
    if (acceptedTypes[fileType].indexOf(extension) === -1) return false
    return true
  }

  const sanitizeURL = dataURL => {
    const searchedSubstring = 'base64'
    const searcedSubstringIndex = dataURL.indexOf(searchedSubstring)
    let subStringToBeReplaced = ''
    if (searcedSubstringIndex !== -1) {
      subStringToBeReplaced = dataURL.slice(
        0,
        searcedSubstringIndex + searchedSubstring.length + 1
      )
    }

    return dataURL.replace(subStringToBeReplaced, '')
  }

  const chooseOptions = {
    className: 'custom-choose-btn p-button-rounded p-button-outlined',
    icon: `pi ${fileType === 'docx' ? 'pi-file-word' : 'pi-file-excel'}`,
    iconOnly: false
  }
  const uploadOptions = {
    className:
      'custom-upload-btn p-button-success p-button-rounded p-button-outlined hidden',
    icon: 'pi pi-fw pi-cloud-upload',
    iconOnly: true
  }
  const cancelOptions = {
    icon: 'pi pi-fw pi-times',
    iconOnly: true,
    className:
      'custom-cancel-btn p-button-danger p-button-rounded p-button-outlined'
  }

  return (
    <div className='upload-container'>
      {/* <LoadingSpinner /> */}
      {/* {isMaxPageNumberModalVisible && (
        <WarningDialog
          onStepsNumberModalHide={() => {
            setIsMaxPageNumberModalVisible(false)
          }}
          ref={fileUploadRef}
          maxPageNumber={MAX_PAGE_NUMBER}
        />
      )} */}
      <Toast ref={toastRef} />
      <Tooltip target='.custom-upload-btn' content='Upload' position='bottom' />
      {/* <Tooltip
        target=".custom-cancel-btn"
        content="Clear"
        autoHide
        position="bottom"

      /> */}
      <FileUpload
        className={styles.uploadcard}
        ref={fileUploadRef}
        name=''
        accept={acceptedTypes[fileType].map(item => `.${item}`).join(',')}
        customUpload
        uploadHandler={handleDocumentUpload}
        maxFileSize={100000000}
        onBeforeDrop={beforeDropHandler}
        // onBeforeSelect={beforeSelectHandler}
        onUpload={onTemplateUpload}
        onSelect={onTemplateSelect}
        onError={onTemplateClear}
        onClear={onTemplateClear}
        // headerTemplate={headerTemplate}
        itemTemplate={itemTemplate}
        emptyTemplate={emptyTemplate}
        chooseLabel={`${assets.BUTTONS.CHOOSE}`}
        chooseOptions={
          //   isDataExtracted
          //     ? {
          //         ...chooseOptions,
          //         className: `hidden ${chooseOptions.className}`
          //       }
          //     :
          chooseOptions
        }
        uploadOptions={
          //   isDataExtracted
          //     ? {
          //         ...uploadOptions,
          //         className: `p-disabled ${uploadOptions.className}`
          //       }
          //     :
          uploadOptions
        }
        cancelOptions={cancelOptions}
      />
      {fileType === 'csv' && isAutomatedPermissionsCheckBoxVisible && (
        <div style={{
          marginTop: '10px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start'
        }}
        >
          <small style={{

            fontSize: '0.75rem',
            marginBottom: '8px'
          }}
          >
            {assets.DESCRIPTIONS.AUTOMATED_PERMISSIONS}
          </small>

          <div style={{
            display: 'flex',
            alignItems: 'center'
          }}
          >
            <Checkbox
              // className={!enableAutomatedPermissions ? 'p-invalid' : ''}
              onChange={e => setIsAutomatedPermissionsChecked(e.checked)}
              checked={isAutomatedPermissionsChecked}
              disabled={!enableAutomatedPermissions}
              style={{
                marginRight: '5px',
                alignSelf: 'center'
              }}
            />
            <label
              className='p-checkbox-label' style={{
                fontWeight: 600,
                opacity: !isAutomatedPermissionsChecked ? 0.75 : 1
              }}
            >
              {assets.HEADERS.GIVE_AUTOMATED_PERMISSIONS}
            </label>
          </div>
        </div>
      )}

    </div>
  )
}
