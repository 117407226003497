import { React, forwardRef } from 'react'
import { Dialog } from 'primereact/dialog'
import styles from './WarningDialog.module.css'

const WarningDialog = forwardRef((props, fileUploadRef) => {
  const onStepsNumberModalHide = () => {
    props.onStepsNumberModalHide()
    fileUploadRef?.current.clear()
  }
  return (
    <Dialog
      className={styles['warning-message-dialog']}
      visible
      style={{ width: '33%' }}
      onHide={() => onStepsNumberModalHide()}
    >
      <div className={styles['warning-message-icon']}>
        <i
          className='pi pi-exclamation-triangle'
          style={{ fontSize: '2rem', color: 'orange' }}
        />
      </div>
      <div className={styles['warning-message-container']}>
        <p className={styles['warning-message']}>
          {'Please split your document. The maximum number of pages is '}
          <span style={{ color: 'red' }}>{props.maxPageNumber}.</span>
          <br />
          <br />
          <a target='blank' href='https://www.ilovepdf.com/split_pdf'>
            PDF Splitting tool
          </a>
        </p>
      </div>
    </Dialog>
  )
})

WarningDialog.displayName = 'WarningDialog'

export default WarningDialog
