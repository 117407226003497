// global lcoalStorage

export const loadState = () => {
  try {
    const serializedState = window.localStorage.getItem('global-state')
    if (serializedState === null) {
      return undefined
    }
    return JSON.parse(serializedState)
  } catch (e) {
    return undefined
  }
}

export const saveState = (state) => {
  try {
    const serializedState = JSON.stringify({
      loginUserReducer: {
        ...state.loginUserReducer,
        fetching: false
      }
    //   user: state.loginUserReducer
    })
    window.localStorage.setItem('global-state', serializedState)
  } catch (e) {
    console.warn(e)
  }
}
