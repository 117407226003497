import { SERVER } from 'config/global'
import axios from 'axios'
import store from 'stores/store'

export const APPLY_TAG = 'APPLY_TAG'
export const DELETE_TAG = 'DELETE_TAG'

export function applyTag (user, tagDefinitionId, certificateIdList, templateId, page = '', filter = '', sortField = '', sortOrder = '') {
  const token = store.getState().loginUserReducer.user.token

  return {
    type: APPLY_TAG,
    payload: async () => {
      await axios.post(`${SERVER}/cert-generator-api/users/${user._id}/tag-definitions/${tagDefinitionId}/tags`, certificateIdList, {
        headers: { Authorization: token }
      })
      const response = axios.get(
        `${SERVER}/cert-generator-api/templates/${templateId}/certificates?page=${page}${filter ? '&' + filter : ''
        }&sortField=${sortField}&sortOrder=${sortOrder}`,
        { headers: { Authorization: token } })

      return response
    }
  }
}

export function deleteTag (tagId, templateId, page = '', filter = '', sortField = '', sortOrder = '') {
  const token = store.getState().loginUserReducer.user.token

  return {
    type: DELETE_TAG,
    payload: async () => {
      await axios.delete(`${SERVER}/cert-generator-api/tags/${tagId}`, { headers: { Authorization: token } })
      const response = axios.get(
        `${SERVER}/cert-generator-api/templates/${templateId}/certificates?page=${page}${filter ? '&' + filter : ''
        }&sortField=${sortField}&sortOrder=${sortOrder}`,
        { headers: { Authorization: token } })

      return response
    }
  }
}
