import { SERVER } from 'config/global'
import axios from 'axios'
import store from 'stores/store'

export const GET_APPLICABLE_TAG_DEFINITIONS = 'GET_APPLICABLE_TAG_DEFINITIONS'
export const GET_TAG_DEFINITIONS = 'GET_TAG_DEFINITIONS'
export const ADD_TAG_DEFINITION = 'ADD_TAG_DEFINITION'
export const UPDATE_TAG_DEFINITION = 'UPDATE_TAG_DEFINITION'
export const DELETE_TAG_DEFINITION = 'DELETE_TAG_DEFINITION'
export const GET_TAG_DEFINITIONS_SUGGESTIONS = 'GET_TAG_DEFINITIONS_SUGGESTIONS'

export function addTagDefinition (
  tagDefinition,
  page = 0,
  sortField = '_id',
  sortOrder = -1,
  filterString = ''
) {
  const token = store.getState().loginUserReducer.user.token
  return {
    type: ADD_TAG_DEFINITION,
    payload: async () => {
      await axios.post(`${SERVER}/cert-generator-api/tag-definitions`, tagDefinition, {
        headers: { Authorization: token }
      })
      const response = axios.get(
        `${SERVER}/cert-generator-api/tag-definitions?page=${page}&sortField=${sortField}&sortOrder=${sortOrder}&${filterString}`,
        { headers: { Authorization: token } }
      )
      return response
    }
  }
}

export function deleteTagDefinition (
  tagId,
  page = 0,
  sortField = '_id',
  sortOrder = -1,
  filterString = ''
) {
  const token = store.getState().loginUserReducer.user.token
  return {
    type: DELETE_TAG_DEFINITION,
    payload: async () => {
      await axios.delete(`${SERVER}/cert-generator-api/tag-definitions/${tagId}`, {
        headers: { Authorization: token }
      })
      const response = axios.get(
        `${SERVER}/cert-generator-api/tag-definitions?page=${page}&sortField=${sortField}&sortOrder=${sortOrder}&${filterString}`,
        { headers: { Authorization: token } }
      )
      return response
    }
  }
}

export function getApplicableTagDefinitions (user) {
  const loginUser = store.getState().loginUserReducer.user
  const { token, facets } = loginUser
  if (facets.indexOf('cert-generator') !== -1) {
    return {
      type: GET_APPLICABLE_TAG_DEFINITIONS,
      payload: axios.get(
        `${SERVER}/cert-generator-api/users/${user._id}/tag-definitions?ignorePagination=true`,
        { headers: { Authorization: token } }
      )
    }
  } else {
    return {
      type: GET_APPLICABLE_TAG_DEFINITIONS,
      payload: axios.get(
        `${SERVER}/student-api/students/${user._id}/tag-definitions?ignorePagination=true`,
        { headers: { Authorization: token } }
      )
    }
  }
}

export function getTagDefinitions (
  page = 0,
  sortField = '_id',
  sortOrder = -1,
  filterString = ''
) {
  const token = store.getState().loginUserReducer.user.token
  return {
    type: GET_TAG_DEFINITIONS,
    payload: axios.get(
      `${SERVER}/cert-generator-api/tag-definitions?page=${page}&sortField=${sortField}&sortOrder=${sortOrder}&${filterString}`,
      { headers: { Authorization: token } }
    )
  }
}

export function updateTagDefinition (
  tagDefinition,
  page = 0,
  sortField = '_id',
  sortOrder = -1,
  filterString = ''
) {
  const token = store.getState().loginUserReducer.user.token
  return {
    type: UPDATE_TAG_DEFINITION,
    payload: async () => {
      await axios.put(
        `${SERVER}/cert-generator-api/tag-definitions/${tagDefinition._id}`,
        tagDefinition,
        { headers: { Authorization: token } }
      )
      const response = axios.get(
        `${SERVER}/cert-generator-api/tag-definitions?page=${page}&sortField=${sortField}&sortOrder=${sortOrder}&${filterString}`,
        { headers: { Authorization: token } }
      )
      return response
    }
  }
}

export function getTagDefinitionsSuggestions (filter = '', tagsToInclude = []) {
  const token = store.getState().loginUserReducer.user.token
  const certgeneratorFacet = store
    .getState()
    .loginUserReducer.user.facets.find(element => element === 'cert-generator-api')
  const userId = store.getState().loginUserReducer.user._id
  if (certgeneratorFacet) {
    return {
      type: GET_TAG_DEFINITIONS_SUGGESTIONS,
      payload: axios.post(
        `${SERVER}/cert-generator-api/tag-definitions-suggest?filter=${filter}`,
        tagsToInclude,
        { headers: { Authorization: token } }
      )
    }
  } else {
    return {
      type: GET_TAG_DEFINITIONS_SUGGESTIONS,
      payload: axios.post(
        `${SERVER}/cert-generator-api/users/${userId}/tag-definitions-suggest?filter=${filter}`,
        tagsToInclude,
        { headers: { Authorization: token } }
      )
    }
  }
}
