import axios from 'axios'

import { SERVER } from 'config/global'
import store from 'stores/store'

const ADD_TEMPLATE = 'ADD_TEMPLATE'
const DELETE_TEMPLATE = 'DELETE_TEMPLATE'
const GET_OWNED_TEMPLATES = 'GET_OWNED_TEMPLATES'
const GET_TEMPLATES = 'GET_TEMPLATES'

export function getTemplates (
  page = '',
  filter = '',
  sortField = '',
  sortOrder = ''
) {
  const token = store.getState().loginUserReducer.user.token
  return {
    type: GET_TEMPLATES,
    payload: axios.get(
      `${SERVER}/cert-generator-api/templates?page=${page}${
        filter ? '&' + filter : ''
      }&sortField=${sortField}&sortOrder=${sortOrder}`,
      { headers: { Authorization: token } }
    )
  }
}

export function getOwnedTemplates (
  userId,
  page = '',
  filter = '',
  sortField = '',
  sortOrder = ''
) {
  const token = store.getState().loginUserReducer.user.token

  return {
    type: GET_OWNED_TEMPLATES,
    payload: axios.get(
      `${SERVER}/cert-generator-api/users/${userId}/templates?page=${page}${
        filter ? '&' + filter : ''
      }&sortField=${sortField}&sortOrder=${sortOrder}`,
      { headers: { Authorization: token } }
    )
  }
}

export function addTemplate (
  userId,
  template,
  page = '',
  filter = '',
  sortField = '',
  sortOrder = ''
) {
  const token = store.getState().loginUserReducer.user.token
  return {
    type: ADD_TEMPLATE,
    payload: async () => {
      let response = await axios.post(
        `${SERVER}/cert-generator-api/users/${userId}/templates`,
        template,
        { headers: { Authorization: token } }
      )
      response = await axios.get(
        `${SERVER}/cert-generator-api/users/${userId}/templates?page=${page}${
          filter ? '&' + filter : ''
        }&sortField=${sortField}&sortOrder=${sortOrder}`,
        { headers: { Authorization: token } }
      )

      return response
    }
  }
}

export function deleteTemplate (
  userId,
  templateId,
  page = '',
  filter = '',
  sortField = '',
  sortOrder = ''
) {
  const token = store.getState().loginUserReducer.user.token
  return {
    type: DELETE_TEMPLATE,
    payload: async () => {
      let response = await axios.delete(
        `${SERVER}/cert-generator-api/users/${userId}/templates/${templateId}`,
        { headers: { Authorization: token } }
      )
      response = await axios.get(
        `${SERVER}/cert-generator-api/users/${userId}/templates?page=${page}${
          filter ? '&' + filter : ''
        }&sortField=${sortField}&sortOrder=${sortOrder}`,
        { headers: { Authorization: token } }
      )
      return response
    }
  }
}
