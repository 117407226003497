import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import './CertificateShareDialog.css'

import Select from 'react-select'
import { Dialog } from 'primereact/dialog'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Button } from 'primereact/button'

import { certificateActions, userSuggestionsActions } from 'actions'
import assets from 'assets'

const userSelector = state => state.loginUserReducer.user
const usersSharedCertificateSelector = state => state.certificateReducer.sharedCertificateUsersList
const studentSuggestionsSelector = state => state.userReducer.userSuggestions

const CertificateShareDialog = (props) => {
  const { certificate } = props
  const dispatch = useDispatch()

  const [studentSuggestionOptions, setStudentSuggestionOptions] = useState([])
  const [dataTableUsers, setDataTableUsers] = useState([])
  const [selectedSuggestion, setSelectedSuggestion] = useState(null)
  const [disableShare, setDisableShare] = useState(true)

  const usersSharedCertificateList = useSelector(usersSharedCertificateSelector, shallowEqual)
  const studentSuggestionList = useSelector(studentSuggestionsSelector, shallowEqual)

  useEffect(() => {
    dispatch(userSuggestionsActions.getStudentsSuggestions(''))
  }, [])

  useEffect(() => {
    if (certificate) {
      dispatch(certificateActions.getUsersSharedCertificate(certificate))
    }
  }, [certificate])

  useEffect(() => {
    const data = usersSharedCertificateList.map(u => ({
      permId: u.permId,
      roleDescription: u.roleDescription,
      roleId: u.roleId,
      user: {
        fullName: u.user.fullName,
        userId: u.user.userId,
        username: u.user.username,
        fullNameAndUsername: `${u.user.fullName} (${u.user.username})`
      }
    }))

    setDataTableUsers(data)
  }, [usersSharedCertificateList])

  useEffect(() => {
    const options = []
    for (const suggestion of studentSuggestionList) {
      options.push({
        suggestion,
        value: suggestion._id,
        label: `${suggestion.fullName} (${suggestion.username})`
      })
    }
    setStudentSuggestionOptions(options)
  }, [studentSuggestionList])

  const handleSelectChange = (values) => {
    setSelectedSuggestion(values)
    if (values) {
      setDisableShare(false)
    } else {
      setDisableShare(true)
    }
  }

  const handleSelectInputChange = (value) => {
    dispatch(userSuggestionsActions.getStudentsSuggestions(`username=${value}`))
  }

  const opsTemplate = (rowData) => {
    return (
      <div className='p-grid p-justify-center p-mt-1 ops-template'>
        <Button
          icon='pi pi-times' className='p-button p-button-danger small-margin share-certificate-button'
          onClick={() => { dispatch(certificateActions.removeCertificateSharePermission(certificate, rowData.permId, rowData.user.userId, rowData.roleId)) }}
        />
      </div>
    )
  }

  const dialogFooter = (
    <div>
      <Button
        className='p-button p-button-warning' label={assets.GENERAL.CANCEL}
        onClick={() => props.onHide()}
      />
    </div>
  )

  const customStyles = {
    menu: (provided, state) => ({
      ...provided,
      marginTop: 0,
      zIndex: 1001
    })
  }

  return (
    <div className='certificate-share-dialog'>
      <Dialog
        header={assets.HEADERS.SHARE_CERTIFICATE}
        footer={dialogFooter}
        {...props}
        modal
        maximizable
        contentStyle={{ maxWidth: '100vw', overflow: 'auto' }}
        className='certificate-dialog'
      >
        <div className='p-grid p-justify-center'>

          <div className='p-grid p-justify-center p-align-center centered select-container p-mb-4 p-mt-2'>
            <Select
              className='react-select p-col-12 p-md-8'
              placeholder={assets.DESCRIPTIONS.SELECT_USER}
              options={studentSuggestionOptions}
              id='username'
              pageSize={4}
              menuPosition='absolute'
              value={selectedSuggestion}
              onChange={handleSelectChange}
              onInputChange={handleSelectInputChange}
              styles={customStyles}
              isClearable
            />
            <div className='p-col-12 p-md-4'>
              <div>
                <Button
                  label={assets.BUTTONS.SHARE} disabled={disableShare} className='p-button p-button-text p-button-outlined spaced p-button-info small-margin share-certificate-button'
                  onClick={(rowData) => {
                    dispatch(certificateActions.shareCertificate(selectedSuggestion.value, certificate))
                  }}
                />
              </div>
            </div>
          </div>

          {!usersSharedCertificateList.length > 0 ? <div className='datatable-empty-message'>{assets.DESCRIPTIONS.NO_SHARED_USERS}</div> : null}

          <div>
            {usersSharedCertificateList.length > 0
              ? <>
                <DataTable
                  value={dataTableUsers}
                >
                  <Column field='user.fullNameAndUsername' header={assets.HEADERS.SHARED_USERS} />
                  <Column body={opsTemplate} />
                </DataTable>
              </>
              : null}
          </div>
        </div>
      </Dialog>
    </div>

  )
}

export default CertificateShareDialog
