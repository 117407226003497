import { loginActions } from 'actions'

const tokenGuardMiddleware = store => next => action => {
  if (action.type.includes('_REJECTED') && action.payload?.response?.data?.status?.message === 'ERR_EXPIRED_TOKEN') {
    window.sessionStorage.removeItem('isPageInitiated')
    store.dispatch(loginActions.resetTokenState())
  }
  return next(action)
}

export default tokenGuardMiddleware
