import { applyMiddleware, createStore } from 'redux'
import thunk from 'redux-thunk'
import promise from 'redux-promise-middleware'
import throttle from 'lodash/throttle'
import tokenGuard from './token-guard'
import rootReducer from 'reducers'
import logger from 'redux-logger'
import { loadState, saveState } from './local-storage'

const persistedState = loadState()

const middlewareList = process.env.NODE_ENV === 'dev' ? [promise, thunk, logger, tokenGuard] : [promise, thunk, tokenGuard]

const store = createStore(rootReducer, persistedState, applyMiddleware(...middlewareList))

store.subscribe(
  throttle(() => {
    saveState(store.getState())
  }),
  1000
)

export default store
