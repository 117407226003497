import assets from 'assets'
import { Button } from 'primereact/button'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import React, { useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import './PermissionsCenter.css'
import { loginActions, userSuggestionsActions } from 'actions'
import Select from 'react-select'
import { Dialog } from 'primereact/dialog'

// user suggestions
const usersSuggestionsSelector = state => state.userReducer.userSuggestions
// const usersSuggestionsFetchingSelector = state => state.userReducer.fetching
// const userSuggestionsCountSelector = state => state.userReducer.count

// certgenerators
const certGeneratorsSelector = state => state.userReducer.certGenerators
// const certGeneratorsFetchingSelector = state => state.userReducer.fetching
// const certGeneratorsCountSelector = state => state.userReducer.certGeneratorsCount

const PermissionsCenter = () => {
  const dispatch = useDispatch()

  const pageSize = 10

  const [usersSuggestionsOptions, setUsersSuggestionsOptions] = useState([])
  const [isAddNewPermissionDialogVisislbe, setisAddNewPermissionDialogVisible] = useState(false)
  const [selectedUserSuggestion, setSelectedUserSuggestion] = useState(null)
  const [idToBeDeleted, setIdToBeDeleted] = useState(null)
  const [isDeleteAccessDialogVisible, setIsDeleteAccessDialogVisible] = useState(false)
  const usersSuggestions = useSelector(usersSuggestionsSelector, shallowEqual)

  const [disableShare, setDisableShare] = useState(true)
  // cert generators
  const certGenerators = useSelector(certGeneratorsSelector)

  useEffect(() => {
    const options = []
    for (const suggestion of usersSuggestions) {
      options.push({
        suggestion,
        value: suggestion._id,
        label: `${suggestion.fullName} (${suggestion.username})`
      })
    }
    setUsersSuggestionsOptions(options)
  }, [usersSuggestions, certGenerators])

  useEffect(() => {
    dispatch(userSuggestionsActions.getCertGenerators())
    dispatch(userSuggestionsActions.getTeachersSuggestions(''))
  }, [])

  // useEffect(() => {
  //   dispatch(userSuggestionsActions.getTeachersSuggestions(filterString))
  // }, [dispatch, filterString])

  const handleSelectInputChange = (value) => {
    dispatch(userSuggestionsActions.getTeachersSuggestions(`username=${value}`))
  }

  const handleUsersSuggestionChange = (value) => {
    setSelectedUserSuggestion(value)
    if (value) {
      setDisableShare(false)
    } else {
      setDisableShare(true)
    }
  }

  const handleShowAddNewPermissionDialog = evt => {
    setisAddNewPermissionDialogVisible(true)
  }

  const handleHideAddNewPermissionDialog = () => {
    setisAddNewPermissionDialogVisible(false)
  }

  const handleHideDeleteAccessDialog = () => {
    setIsDeleteAccessDialogVisible(false)
  }

  const handleShowDeleteAccessDialog = (rowData) => {
    setIdToBeDeleted(rowData._id)
    setIsDeleteAccessDialogVisible(true)
  }

  const handleDeleteAccessForUser = () => {
    dispatch(userSuggestionsActions.updateCertGeneratorFacet([idToBeDeleted], 'delete'))
    setIsDeleteAccessDialogVisible(false)
  }

  const handleLogOut = () => {
    window.sessionStorage.removeItem('isPageInitiated')
    dispatch(loginActions.resetTokenState())
  }

  const tableFooter = (
    <div className='actions'>
      <span className='spaced-sideways add-permission-btn'>
        <Button
          label={`${assets.BUTTONS.ADD_NEW_PERMISSIONS}`}
          onClick={handleShowAddNewPermissionDialog}
          icon='pi pi-plus'
        />

      </span>
      {process.env.REACT_APP_INCLUDE_NXCERTIFICATES_LOGIN === 'true' && <Button
        className='delete-certificate-button'
        icon='pi pi-sign-out'
        label={assets.BUTTONS.LOGOUT}
        onClick={handleLogOut}
                                                                        />}
    </div>
  )

  const deleteAccessDialogFooter = (
    <div>
      <Button
        label={assets.GENERAL.CANCEL}
        icon='pi pi-times'
        onClick={handleHideDeleteAccessDialog}
        className='p-button-text'
      />
      <Button
        label={assets.EVENT.CONFIRM_EVENT}
        icon='pi pi-check'
        onClick={handleDeleteAccessForUser}
        autoFocus
      />
    </div>
  )

  const dialogFooter = (
    <div>
      <Button
        className='p-button p-button-warning' label={assets.GENERAL.CANCEL}
        onClick={handleHideAddNewPermissionDialog}
      />
    </div>
  )

  const customStyles = {
    menu: (provided, state) => ({
      ...provided,
      marginTop: 0,
      zIndex: 1001
    })
  }

  return (
    <div>
      <div className='permissions-editor'>
        <DataTable
          value={certGenerators}
          paginator
          rows={pageSize}
          footer={tableFooter}
        >
          <Column
            field='username'
            header={assets.PERMISSIONS_CENTER.GENERATOR_NAME}
            className='wrap-any'
            sortable

          />

          <Column
            style={{ width: '15em' }}
            body={rowData => (
              <div className='centered permissions-options'>

                <Button
                  icon='pi pi-times'
                  className='p-button-danger'
                  label={assets.PERMISSIONS_CENTER.ELIMINATE_ACCESS}
                  onClick={() => handleShowDeleteAccessDialog(rowData)}
                />
              </div>
            )}
          />
        </DataTable>
        {/* {dialog for confirmation of elimination} */}
        {isAddNewPermissionDialogVisislbe &&
         (
           <div className='certificate-share-dialog'>
             <Dialog
               header='Selecteaza profesorul'
               footer={dialogFooter}
          //  {...props}
               visible={isAddNewPermissionDialogVisislbe}
               modal
               onHide={handleHideAddNewPermissionDialog}
               className='certificate-dialog'
             >
               <div className='p-grid p-justify-center'>

                 <div className=' centered select-container p-mb-4 p-mt-2'>
                   <Select
                     className='react-select p-col-12 p-md-8'
                     placeholder='Selecteaza un utilizator'
                     options={usersSuggestionsOptions}
                     id='username'
                     pageSize={4}
                     menuPosition='absolute'
                     value={selectedUserSuggestion}
                     onChange={handleUsersSuggestionChange}
                     onInputChange={handleSelectInputChange}
                     styles={customStyles}
                     isClearable
                   />
                   <div className='p-col-12 p-md-4'>
                     <div>
                       <Button
                         label={assets.BUTTONS.SHARE} disabled={disableShare} className='p-button p-button-text p-button-outlined spaced p-button-info small-margin share-certificate-button'
                         onClick={(rowData) => {
                           const selectedUserSuggestionId = selectedUserSuggestion.value
                           dispatch(userSuggestionsActions.updateCertGeneratorFacet(selectedUserSuggestionId, 'add'))
                           setSelectedUserSuggestion(null)
                           setisAddNewPermissionDialogVisible(false)
                         }}
                       />
                     </div>
                   </div>
                 </div>
               </div>
             </Dialog>
           </div>
         )}
        {isDeleteAccessDialogVisible && (
          <Dialog
            header={assets.HEADERS.DELETE_FACET_CONFIRMATION}
            onHide={handleHideDeleteAccessDialog}
            visible={isDeleteAccessDialogVisible}
            footer={deleteAccessDialogFooter}
          >
            <p className='m-0'>
              {assets.HEADERS.DELETE_FACET_CONFIRMATION}

            </p>

          </Dialog>
        )}
      </div>
    </div>

  )
}

export default PermissionsCenter
