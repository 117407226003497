import { Buffer } from 'buffer'
import axios from 'axios'
import store from 'stores/store'

import { SERVER } from 'config/global'

export const LOGIN = 'LOGIN'
export const LOGOUT = 'LOGOUT'
export const SET_INITIAL_STATE = 'SET_INITIAL_STATE'

export function login (username, password) {
  return {
    type: LOGIN,
    payload: axios.post(
      `${SERVER}/login-api/user`,
      {},
      {
        headers: {
          Authorization: `Basic ${Buffer.from(
            username + ':' + password
          ).toString('base64')}`
        }
      }
    )
  }
}

// export function logout (userId) {
//   const token = store.getState().loginUserReducer.user.token
//   return {
//     type: LOGOUT,
//     payload: axios.delete(
//       `${SERVER}/login-api/user/${userId}`, { }, { headers: { Authorization: token } }
//     )
//   }
// }

export function setInitialState (token) {
  return {
    type: 'SET_INITIAL_STATE',
    payload: axios.put(`${SERVER}/login-api/user`, { token }, { headers: { Authorization: token } })
  }
}

export function resetTokenState () {
  return {
    type: 'RESET_TOKEN_STATE',
    payload: {}
  }
}

// export const login = (username, password) => {
//   return async dispatch => {
//     await fetch(`${apiURL}/login-api/user`, {
//       method: 'POST',
//       headers: {
//         Authorization:
//           'Basic ' + Buffer.from(username + ':' + password).toString('base64')
//       }
//     })
//       .then(response => response.json())
//       .then(data => {
//         if (data.token && data.profileId)
//           dispatch({
//             type: 'LOGIN',
//             payload: { userToken: data?.token, userProfileId: data?.profileId }
//           })
//         else dispatch({ type: 'LOGIN_REJECTED', payload: data })
//       })
//       .catch(error => {
//         console.error(error)
//       })
//   }
// }

// export const login = (username, password) => {
//   return async dispatch => {
//     await fetch(`${apiURL}/login-api/user`, {
//       method: 'POST',
//       headers: {
//         Authorization:
//           'Basic ' + Buffer.from(username + ':' + password).toString('base64')
//       }
//     })
//       .then(response => response.json())
//       .then(data => {
//         if (data.token && data.profileId)
//           dispatch({
//             type: 'LOGIN',
//             payload: { userToken: data?.token, userProfileId: data?.profileId }
//           })
//         else dispatch({ type: 'LOGIN_REJECTED', payload: data })
//       })
//       .catch(error => {
//         console.error(error)
//       })
//   }
// }
