import { SERVER } from 'config/global'
import axios from 'axios'
import store from 'stores/store'

export const GET_STUDENTS_SUGGESTIONS = 'GET_STUDENTS_SUGGESTIONS'
export const GET_TEACHERS_SUGGESTIONS = 'GET_TEACHERS_SUGGESTIONS'
export const GET_CERT_GENERATORS = 'GET_CERT_GENERATORS'
export const ADD_USER_PERMISSION = 'ADD_USER_PERMISSION'
export const REMOVE_CERTIFICATE_USER_SHARE_PERMISSION = 'REMOVE_CERTIFICATE_USER_SHARE_PERMISSION'
export const ADD_CERT_GENERATOR_FACET = 'ADD_CERT_GENERATOR_FACET'

export function getStudentsSuggestions (filter = '') {
  const token = store.getState().loginUserReducer.user.token
  return {
    type: GET_STUDENTS_SUGGESTIONS,
    payload: axios.get(
      `${SERVER}/cert-generator-api/users?${filter ? '&' + filter : ''}`,
      { headers: { Authorization: token } }
    )
  }
}

export function getTeachersSuggestions (filter = '') {
  const token = store.getState().loginUserReducer.user.token
  return {
    type: GET_TEACHERS_SUGGESTIONS,
    payload: axios.get(
      `${SERVER}/admin-api/users?${filter ? '&' + filter : ''}`,
      { headers: { Authorization: token } }
    )
  }
}

export function getCertGenerators () {
  const token = store.getState().loginUserReducer.user.token
  return {
    type: GET_CERT_GENERATORS,
    payload: axios.get(
      `${SERVER}/admin-api/cert-generators`,
      { headers: { Authorization: token } }
    )
  }
}

export function updateCertGeneratorFacet (userId, action) {
  const token = store.getState().loginUserReducer.user.token
  return {
    type: ADD_CERT_GENERATOR_FACET,
    payload: async () => {
      let response = await axios.put(
            `${SERVER}/admin-api/users/`,
            { userId, action },
            { headers: { Authorization: token } }
      )

      response = axios.get(`${SERVER}/admin-api/cert-generators`,
        { headers: { Authorization: token } }
      )

      return response
    }
  }
}
