import React, { useEffect, useRef, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'
import { tagDefinitionActions } from 'actions'
import useQuery from 'hooks/useQuery'
import assets from 'assets'
import { Button } from 'primereact/button'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { Dialog } from 'primereact/dialog'
import { InputText } from 'primereact/inputtext'
import Select from 'react-select'
import SimpleReactValidator from 'simple-react-validator'
import ConfirmationDialog from 'components/ConfirmationDialog'

import './TagDefinitionEditor.css'

const TagDefinitionEditor = () => {
  const validator = useRef(
    new SimpleReactValidator({
      element: message => <div style={{ color: 'tomato' }}>{message}</div>,
      messages: {
        required: assets.GENERAL.VALIDATION.FIELD_REQUIRED
      }
    })
  )
  const [, updateState] = React.useState()
  const forceUpdate = React.useCallback(() => updateState({}), [])

  const location = useLocation()
  const dispatch = useDispatch()
  const { stateParams } = useParams()
  //   const history = useHistory()
  const pageSize = 10
  const query = useQuery()

  // guery params
  const pageQuery = query.get('page')
    ? isNaN(query.get('page'))
      ? 0
      : Number(query.get('page'))
    : null
  const sortFieldQuery = query.get('sortField')
  const sortOrderQuery = query.get('sortOrder') ?? -1
  const fields = ['name', 'description']
  const filtersQuery = {}
  fields.forEach(field => {
    const value = query.get(field)
    if (value) {
      filtersQuery[field] = { value, matchMode: 'startsWith' }
    }
  })

  const [currentPage, setCurrentPage] = useState(pageQuery)
  const [sortField, setSortField] = useState(sortFieldQuery)
  const [sortOrder, setSortOrder] = useState(sortOrderQuery)
  const [filters, setFilters] = useState(filtersQuery ?? {})
  const filterKeys = Object.keys(filters)
  const filterString = filterKeys
    .map(e => {
      return `${e}=${filters[e].value}`
    })
    .join('&')

  useEffect(() => {
    if (filterString?.length > 0) {
      setCurrentPage(0)
    }
  }, [filters])

  useEffect(() => {
    if (!stateParams) {
      const equalFilters =
        Object.keys(filtersQuery)?.length > 0 &&
        Object.keys(filtersQuery)?.length === Object.keys(filters)?.length &&
        shallowEqual(filters, filtersQuery)
      if (
        pageQuery !== currentPage ||
        sortFieldQuery !== sortField ||
        sortOrderQuery !== sortOrder ||
        !equalFilters
      ) {
        let newSearch = `?page=${currentPage ?? 0}`
        if (sortField?.length) {
          newSearch += `&sortField=${sortField}&sortOrder=${sortOrder}`
        }
        if (filterString?.length > 0) {
          newSearch += `&${filterString}`
        }
        // window.history.replaceState(
        //   { page: currentPage, sortField, sortOrder },
        //   '',
        //   `/#${location.pathname}${newSearch}`
        // )
      }
    }
  }, [currentPage, sortField, sortOrder, filterString])

  useEffect(() => {
    if (!stateParams) {
      // we are on container route
      dispatch(
        tagDefinitionActions.getTagDefinitions(
          currentPage ?? 0,
          sortField,
          sortOrder,
          filterString
        )
      )
    }
  }, [currentPage, sortField, sortOrder, filterString])

  const { tagDefinitionList, count, fetching } = useSelector(
    state => state.tagDefinitionReducer
  )
  const [selectedTagDefinition, setSelectedTagDefinition] = useState(null)
  const [newTagDefinition, setNewTagDefinition] = useState(false)
  const [tagDefinition, setTagDefinition] = useState({
    name: '',
    description: '',
    allowedTypes: [],
    forFacets: []
  })
  const [isAddDialogShown, setIsAddDialogShown] = useState(false)
  const [idToBeDeleted, setIdToBeDeleted] = useState(null)

  const handleSaveTagDefinition = async evt => {
    if (validator.current.allValid()) {
      let res
      if (newTagDefinition) {
        res = await dispatch(
          tagDefinitionActions.addTagDefinition(
            tagDefinition,
            currentPage,
            sortField,
            sortOrder,
            filterString
          )
        )
      } else {
        res = await dispatch(
          tagDefinitionActions.updateTagDefinition(
            tagDefinition,
            currentPage,
            sortField,
            sortOrder,
            filterString
          )
        )
      }
      if (res.value.status === 200) {
        setIsAddDialogShown(false)
        setTagDefinition({
          _id: null,
          name: '',
          description: '',
          forFacets: [],
          allowedTypes: []
        })
      }
    } else {
      validator.current.showMessages()
      forceUpdate()
    }
  }

  const handlePageChange = evt => {
    const page = evt.page
    setCurrentPage(page)
  }

  const handleTagDefinitionSelect = evt => {
    setNewTagDefinition(false)
    const tagDefinitionCopy = Object.assign({}, evt.value)
    setSelectedTagDefinition(tagDefinitionCopy)
  }

  const handleAddNew = evt => {
    setNewTagDefinition(true)
    setTagDefinition({
      name: '',
      description: '',
      forFacets: [],
      allowedTypes: []
    })
    setIsAddDialogShown(true)
  }

  const tableFooter = (
    <div>
      <span className='spaced-sideways add-tag-btn'>
        <Button
          label={`${assets.BUTTONS.ADD_NEW_TAG}`}
          onClick={handleAddNew}
          icon='pi pi-plus'
        />
      </span>
    </div>
  )

  const handleSort = evt => {
    setSortField(evt.sortField)
    setSortOrder(evt.sortOrder)
  }

  const handleFilter = evt => {
    setFilters(evt.filters)
  }

  const editTagDefinition = rowData => {
    const tagDefinitionCopy = Object.assign({}, rowData)
    setIsAddDialogShown(true)
    setTagDefinition(tagDefinitionCopy)
  }

  const deleteTagDefinition = async () => {
    const res = await dispatch(
      tagDefinitionActions.deleteTagDefinition(
        idToBeDeleted,
        currentPage,
        sortField,
        sortOrder,
        filterString
      )
    )
    if (res?.value?.status === 200) {
      setIdToBeDeleted(null)
    }
  }

  const facetList = ['admin', 'certgenerator', 'student']

  const facetOptions = facetList.map(e => {
    return {
      label: e,
      value: e
    }
  })

  const facetValue = tagDefinition.forFacets.map(e => {
    return {
      label: e,
      value: e
    }
  })

  const modelList = ['Certificate']

  const modelOptions = modelList.map(e => {
    return {
      label: e,
      value: e
    }
  })

  const modelValue = tagDefinition.allowedTypes.map(e => {
    return {
      label: e,
      value: e
    }
  })

  const handleTypeSelectChange = values => {
    const updatedTagDefinition = { ...tagDefinition }
    if (values) {
      updatedTagDefinition.allowedTypes = values.map(e => e.value)
    } else {
      updatedTagDefinition.allowedTypes = []
    }
    setTagDefinition(updatedTagDefinition)
  }

  const updateProperty = (property, value) => {
    const updatedTagDefinition = { ...tagDefinition }
    switch (property) {
      default:
        updatedTagDefinition[property] = value
    }
    setTagDefinition(updatedTagDefinition)
  }

  const handleFacetSelectChange = values => {
    const updatedTagDefinition = { ...tagDefinition }
    if (values) {
      updatedTagDefinition.forFacets = values.map(e => e.value)
    } else {
      updatedTagDefinition.forFacets = []
    }
    setTagDefinition(updatedTagDefinition)
  }

  useEffect(() => {
    forceUpdate()
  }, [tagDefinition])

  const isAllValid = validator.current.allValid()

  const dialogFooter = (
    <div className='tag-dialog-footer'>
      <Button
        className='save-tag'
        icon='pi pi-save'
        disabled={!isAllValid || fetching}
        label={assets.GENERAL.SAVE}
        onClick={handleSaveTagDefinition}
      />
      <Button
        className='cancel-save-tag'
        icon='pi pi-times'
        disabled={fetching}
        label={assets.GENERAL.CANCEL}
        onClick={() => {
          setIsAddDialogShown(false)
        }}
      />
    </div>
  )

  return (
    <>
      <div className='tag-definition-editor'>
        <DataTable
          value={tagDefinitionList}
          paginator
          rows={pageSize}
          //   responsive={true}
          footer={tableFooter}
          onPage={handlePageChange}
          first={currentPage * pageSize}
          selectionMode='single'
          loading={fetching}
          lazy
          totalRecords={count}
          selection={selectedTagDefinition}
          onSelectionChange={handleTagDefinitionSelect}
          filters={filters}
          onFilter={handleFilter}
          sortField={sortField}
          sortOrder={Number(sortOrder)}
          onSort={handleSort}
          paginatorLeft={
            <span style={{ visibility: 'hidden' }}>
              {`${assets.GENERAL.TOTAL}: ${count}`}
            </span>
          }
          paginatorRight={
            <span
              style={{
                margin: '0 0.5em',
                lineHeight: '2.286em',
                minWidth: '2.286em'
              }}
            >
              {`${assets.GENERAL.TOTAL}: ${count}`}
            </span>
          }
        >
          <Column
            field='name'
            header={assets.TAG_DEFINITION_LIST.TAG_NAME}
            className='wrap-any'
            sortable
            filter
          />
          <Column
            field='description'
            header={assets.TAG_DEFINITION_LIST.TAG_DESCRIPTION}
            className='wrap-any'
            sortable
            filter
          />
          <Column
            style={{ width: '8em' }}
            body={rowData => (
              <div className='centered tag-definition-options'>
                <Button
                  icon='pi pi-pencil'
                  className='p-button-warning p-mr-2'
                  onClick={() => editTagDefinition(rowData)}
                />
                <Button
                  icon='pi pi-times'
                  className='p-button-danger'
                  onClick={() => setIdToBeDeleted(rowData._id)}
                />
              </div>
            )}
          />
        </DataTable>

        {isAddDialogShown && (
          <Dialog
            visible={isAddDialogShown}
            header={assets.TAG_DEFINITION_LIST.DETAILS_TITLE}
            modal
            footer={dialogFooter}
            onHide={() => setIsAddDialogShown(false)}
            maximizable
            // responsive
            contentStyle={{ overflow: 'visible' }}
          >
            {tagDefinition && (
              <div className='add-tag-dialog p-grid p-fluid p-align-center'>
                <div className='p-col-12'>
                  <div>
                    <label htmlFor='name'>
                      {assets.TAG_DEFINITION_LIST.TAG_NAME}
                    </label>
                  </div>
                  <div>
                    <InputText
                      id='name'
                      onChange={e => updateProperty('name', e.target.value)}
                      value={tagDefinition.name}
                      name='name'
                      onBlur={() => {
                        validator.current.showMessageFor('name')
                        forceUpdate()
                      }}
                    />
                    {validator.current.message(
                      'name',
                      tagDefinition.name,
                      'required'
                    )}
                  </div>
                </div>
                <div className='p-col-12'>
                  <div>
                    <label htmlFor='description'>
                      {assets.TAG_DEFINITION_LIST.TAG_DESCRIPTION}
                    </label>
                  </div>
                  <div>
                    <InputText
                      id='description'
                      onChange={e =>
                        updateProperty('description', e.target.value)}
                      value={tagDefinition.description}
                      name='description'
                    />
                  </div>
                </div>
                <div className='p-col-12'>
                  <div>
                    <label htmlFor='types'>
                      {assets.TAG_DEFINITION_LIST.TAG_TYPE}
                    </label>
                  </div>
                  <div>
                    <Select
                      className='react-select'
                      options={modelOptions}
                      id='types'
                      isMulti
                      value={modelValue}
                      onChange={handleTypeSelectChange}
                      onBlur={() => {
                        validator.current.showMessageFor('modelValue')
                        forceUpdate()
                      }}
                    />
                    {validator.current.message(
                      'modelValue',
                      modelValue,
                      'required'
                    )}
                  </div>
                </div>
                <div className='p-col-12'>
                  <div>
                    <label htmlFor='facets'>
                      {assets.TAG_DEFINITION_LIST.TAG_FACETS}
                    </label>
                  </div>
                  <div>
                    <Select
                      className='react-select'
                      options={facetOptions}
                      id='facets'
                      isMulti
                      value={facetValue}
                      onChange={handleFacetSelectChange}
                      onBlur={() => {
                        validator.current.showMessageFor('facetValue')
                        forceUpdate()
                      }}
                      maxMenuHeight='9em'
                    />
                    {validator.current.message(
                      'facetValue',
                      facetValue,
                      'required'
                    )}
                  </div>
                </div>
              </div>
            )}
          </Dialog>
        )}
      </div>

      <ConfirmationDialog
        disabled={fetching}
        dialogProps={{
          visible: idToBeDeleted !== null,
          onConfirmHandler: deleteTagDefinition,
          onCancelHandler: () => setIdToBeDeleted(null),

          isTitleBarHidden: true,
          confirmationText: `${assets.TAG_DEFINITION_LIST.TAG_CONFIRM_DELETE} ${
            tagDefinitionList.find(tag => tag._id === idToBeDeleted)?.name ?? ''
          }`,
          confirmButtonLabel: assets.EVENT.CONFIRM_EVENT,
          cancelButtonLabel: assets.GENERAL.CANCEL,
          confirmButtonCustomClasses: 'p-button-danger',
          cancelButtonCustomClasses: 'p-button-info',
          dialogHeader: `${assets.HEADERS.DELETE_TAG}`
        }}
      />
    </>
  )
}

export default TagDefinitionEditor
