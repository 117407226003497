const INITIAL_STATE = {
  certificates: [],
  sharedCertificateUsersList: [],
  count: 0,
  error: null,
  fetching: false,
  fetched: false
}

const certificateReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'ADD_CERTIFICATE_PENDING':
    case 'DELETE_CERTIFICATE_PENDING':
    case 'GENERATE_CERTIFICATES_PENDING':
    case 'REGENERATE_CERTIFICATES_PENDING':
    case 'GET_CERTIFICATES_PENDING':
    case 'GET_OWNED_CERTIFICATES_PENDING':
    case 'GET_GENERATED_CERTIFICATES_PENDING':
    case 'GET_USERS_SHARED_CERTIFICATE_PENDING':
    case 'SHARE_CERTIFICATE_PENDING':
    case 'REMOVE_CERTIFICATE_USER_SHARE_PERMISSION_PENDING':
    case 'APPLY_TAG_PENDING':
    case 'DELETE_CERTIFICATES_PENDING':
    case 'DELETE_TAG_PENDING':
      return {
        ...state,
        fetching: true,
        fetched: false,
        error: null
      }
    case 'ADD_CERTIFICATE_FULFILLED':
    case 'DELETE_CERTIFICATE_FULFILLED':
    case 'GENERATE_CERTIFICATES_FULFILLED':
    case 'REGENERATE_CERTIFICATES_FULFILLED':
    case 'GET_CERTIFICATES_FULFILLED':
    case 'GET_OWNED_CERTIFICATES_FULFILLED':
    case 'GET_GENERATED_CERTIFICATES_FULFILLED':
    case 'APPLY_TAG_FULFILLED':
    case 'DELETE_CERTIFICATES_FULFILLED':
    case 'DELETE_TAG_FULFILLED':
      return {
        ...state,
        certificates: action.payload.data.data.certificates,
        count: action.payload.data.data.count,
        error: null,
        fetching: false,
        fetched: true
      }
    case 'GET_USERS_SHARED_CERTIFICATE_FULFILLED':
    case 'SHARE_CERTIFICATE_FULFILLED':
    case 'REMOVE_CERTIFICATE_USER_SHARE_PERMISSION_FULFILLED':
      return {
        ...state,
        sharedCertificateUsersList: action.payload.data.data.sharedCertificateUsersList,
        error: null,
        fetching: false,
        fetched: true
      }
    case 'ADD_CERTIFICATE_REJECTED':
    case 'DELETE_CERTIFICATE_REJECTED':
    case 'GENERATE_CERTIFICATES_REJECTED':
    case 'REGENERATE_CERTIFICATES_REJECTED':
    case 'GET_CERTIFICATES_REJECTED':
    case 'GET_OWNED_CERTIFICATES_REJECTED':
    case 'GET_GENERATED_CERTIFICATES_REJECTED':
    case 'APPLY_TAG_REJECTED':
    case 'DELETE_CERTIFICATES_REJECTED':
    case 'DELETE_TAG_REJECTED':
      return {
        ...state,
        error: action.payload,
        fetching: false,
        fetched: false,
        certificates: []
      }
    case 'GET_USERS_SHARED_CERTIFICATE_REJECTED':
    case 'SHARE_CERTIFICATE_REJECTED':
    case 'REMOVE_CERTIFICATE_USER_SHARE_PERMISSION_REJECTED':
      return {
        ...state,
        error: action.payload,
        fetching: false,
        fetched: false,
        sharedCertificateUsersList: []
      }

    case 'RESET_CERTIFICATES':
      return INITIAL_STATE

    default:
      return state
  }
}

export default certificateReducer
